import { DateTime } from 'luxon';
import React from 'react';

import { ClaimedSpin, isRedeemedSpin, RedeemedSpin } from '../../../api/interfaces/wheel-spins';
import B1Button from '../../../components/buttons/B1Button';
import { SHORT_DATE_FORMAT } from '../../../utils/date-util';

interface ClaimedSpinProps {
  spin: ClaimedSpin | RedeemedSpin;
  onButtonClick: (spin: ClaimedSpin | RedeemedSpin) => void;
}

const PreviousWinCard: React.FC<ClaimedSpinProps> = ({ spin, onButtonClick }) => {
  return (
    <div key={spin.id} className='previous-win'>
      {spin.prize ? (
        spin.prize?.picture ? (
          <div className='spin-prize-picture'>
            <img src={spin.prize.picture} />
          </div>
        ) : (
          <div className='placeholder-image'>
            {spin.prize.type === 'charter_code' ? (
              <span>⛵</span>
            ) : spin.prize.type === 'course' ? (
              <span>🎓</span>
            ) : spin.prize.type === 'discount' ? (
              <span className='discount'>{spin.prize.title?.match(/\d+%/)?.[0]}</span>
            ) : spin.prize.type === 'print' ? (
              <span>
                {spin.prize.title?.toLowerCase().includes('tasse')
                  ? '☕️'
                  : spin.prize.title?.toLowerCase().includes('shirt')
                    ? '👕'
                    : spin.prize.title?.toLowerCase().includes('flasche')
                      ? '🍶'
                      : spin.prize.title?.toLowerCase().includes('cap')
                        ? '🧢'
                        : spin.prize.title?.toLowerCase().includes('kissen')
                          ? '🛋️'
                          : '🎁'}
              </span>
            ) : (
              <span>🎁</span>
            )}
          </div>
        )
      ) : (
        <span>🎁</span>
      )}

      <div className='price-description'>
        <p>{spin.prize?.title.replace('|', '')}</p>
        <p className='small mt-2'>
          Status:{' '}
          {isRedeemedSpin(spin) ? (
            <span>
              Eingelöst am{' '}
              {spin.redeemed_at
                ? DateTime.fromISO(spin.redeemed_at).toLocaleString(SHORT_DATE_FORMAT)
                : ''}
            </span>
          ) : (
            <span>
              Gewonnen am{' '}
              {spin.claimed_at
                ? DateTime.fromISO(spin.claimed_at).toLocaleString(SHORT_DATE_FORMAT)
                : ''}
            </span>
          )}
        </p>
      </div>
      <B1Button className='button-sm' click={() => onButtonClick(spin)}>
        {!isRedeemedSpin(spin) ? 'Einlösen' : 'Infos'}
      </B1Button>
    </div>
  );
};

export default PreviousWinCard;
