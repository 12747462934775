import {
  FirebaseMessaging,
  Notification as FirebaseNotification,
} from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { useIonRouter } from '@ionic/react';
import React, { useEffect, useState } from 'react';

import { addPushToken } from './api/account/account';
import { markPushMessageAsRead } from './api/account/push-messages';
import B1Button from './components/buttons/B1Button';
import B1Modal from './components/modal/B1Modal';
import RequestPushPermissionModal from './components/modal/request-push-permission-modal/RequestPushPermissionModal';

interface GenericPushMessageData {
  push_message_id?: number;
}

interface InternalLinkPushMessageData extends GenericPushMessageData {
  click_action: 'OPEN_INTERNAL_LINK';
  url: string;
}

interface ExternalLinkPushMessageData extends GenericPushMessageData {
  click_action: 'OPEN_EXTERNAL_LINK';
  url: string;
}

type PushMessageData =
  | GenericPushMessageData
  | InternalLinkPushMessageData
  | ExternalLinkPushMessageData;

const isLinkPushMessageData = (
  data?: PushMessageData
): data is InternalLinkPushMessageData | ExternalLinkPushMessageData => {
  return data !== undefined && 'click_action' in data && 'url' in data;
};

const FirebaseMessagingListener: React.FC = () => {
  const ionRouter = useIonRouter();
  const [pushMessageModalOpen, setPushMessageModalOpen] = useState(false);
  const [notification, setNotification] = useState<FirebaseNotification>();
  const [notificationData, setNotificationData] = useState<PushMessageData>();

  useEffect(() => {
    FirebaseMessaging.addListener('notificationReceived', (event) => {
      console.log('Notification received:', { event });

      const data = event.notification.data as PushMessageData;

      setNotification(event.notification);
      setNotificationData(data);
      setPushMessageModalOpen(true);

      const pushMessageId = Number(data.push_message_id);

      if (!isNaN(pushMessageId)) {
        markPushMessageAsRead(pushMessageId);
      }
    });

    const handlePushMessageAction = (notification: FirebaseNotification, data: PushMessageData) => {
      if (data) {
        const pushMessageId = Number(data.push_message_id);

        if (!isNaN(pushMessageId)) {
          markPushMessageAsRead(pushMessageId);
        }

        if (isLinkPushMessageData(data)) {
          switch (data.click_action) {
            case 'OPEN_INTERNAL_LINK': {
              const slug = data.url.split('bootsschule1.app').pop();

              if (slug) {
                ionRouter.push(slug);
              }
              break;
            }
            case 'OPEN_EXTERNAL_LINK':
              setNotification(notification);
              setPushMessageModalOpen(true);
              break;
            default:
              console.log('Unknown data:', data);
              break;
          }
        }
      }
    };

    FirebaseMessaging.addListener('notificationActionPerformed', (event) => {
      const data = event.notification.data as PushMessageData;

      handlePushMessageAction(event.notification, data);
    });

    if (Capacitor.getPlatform() === 'web') {
      if (!navigator.serviceWorker || typeof Notification === 'undefined') return;

      navigator.serviceWorker.addEventListener('message', (event: MessageEvent) => {
        console.log('ServiceWorker message:', { event });

        const data = event.data.data as PushMessageData;

        setNotification(event.data.notification);
        setNotificationData(data);
        setPushMessageModalOpen(true);

        const pushMessageId = Number(data.push_message_id);

        if (!isNaN(pushMessageId)) {
          markPushMessageAsRead(pushMessageId);
        }
      });
    }

    FirebaseMessaging.addListener('tokenReceived', (event) => {
      addPushToken(event.token);
    });
  }, []);

  const getLink = () => {
    if (
      isLinkPushMessageData(notificationData) &&
      notificationData.click_action === 'OPEN_INTERNAL_LINK'
    ) {
      return notificationData.url.split('bootsschule1.app').pop();
    }

    return '';
  };

  const getExternalLink = () => {
    if (
      isLinkPushMessageData(notificationData) &&
      notificationData.click_action === 'OPEN_EXTERNAL_LINK'
    ) {
      return notificationData.url;
    }

    return '';
  };

  return (
    <>
      <B1Modal
        open={pushMessageModalOpen}
        onModalClose={() => setPushMessageModalOpen(false)}
        enableAutoHeight
      >
        <h1 className='emoji'>🔔</h1>
        <h2 className='no-hyphens mb-3'>{notification?.title}</h2>
        <p className='mb-4'>{notification?.body}</p>

        {notification?.image && (
          <img src={notification.image} alt='Notification' className='mb-4' />
        )}
        {getLink() && (
          <B1Button
            href={getLink()}
            routerDirection='forward'
            click={() => setPushMessageModalOpen(false)}
          >
            Mehr erfahren
          </B1Button>
        )}
        {getExternalLink() && (
          <B1Button href={getExternalLink()} external>
            Mehr erfahren
          </B1Button>
        )}
      </B1Modal>

      <RequestPushPermissionModal openAutomatically />
    </>
  );
};

export default FirebaseMessagingListener;
