import { useIonRouter, useIonToast } from '@ionic/react';
import React, { FormEvent, useContext, useState } from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import { Link } from 'react-router-dom';

import B1Button from '../../../components/buttons/B1Button';
import B1Input from '../../../components/forms/B1Input';
import { FeedbackClass } from '../../../components/forms/forms';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import { AuthContext } from '../../../providers/AuthProvider';
import { sendRedeemCodeRequest } from '../../../utils/code-util';
import { formatDuration } from '../../../utils/misc-util';
import Layout from '../../Layout';

import '../../LoginRegister.scss';

const LoginPassword: React.FC = () => {
  const ionRouter = useIonRouter();
  const [presentToast] = useIonToast();

  const { login } = useContext(AuthContext);
  const [registrationData] = useRegistrationDataState();
  const [password, setPassword] = useState('');
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [feedbackClass, setFeedbackClass] = useState<FeedbackClass>();

  const submitPassword = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!registrationData.data.email || !password) {
      setFeedback('Bitte Zugangsdaten eingeben');
      setFeedbackClass('warning');
      return;
    }

    if (!registrationData.code) {
      presentToast({
        message: 'Es wurde kein Code eingegeben!',
        duration: 3000,
        position: 'bottom',
        color: 'danger',
      });
      // TODO: Auf Code-Eingabe-Seite zurückleiten.
      return;
    }

    const loginResult = await login(registrationData.data.email, password, stayLoggedIn);

    if (typeof loginResult === 'boolean') {
      if (loginResult) {
        if (registrationData.code.shipping_needed) {
          setPassword('');
          setStayLoggedIn(false);
          setFeedback('');
          setFeedbackClass(undefined);

          ionRouter.push('/account/address-change', 'forward', 'replace', { unmount: true });
          return;
        }

        const redeemCodeResult = await sendRedeemCodeRequest(registrationData.code);

        if (typeof redeemCodeResult === 'string') {
          presentToast({
            message: redeemCodeResult,
            duration: 3000,
            position: 'bottom',
            color: 'danger',
          });
          // TODO: Auf Code-Eingabe-Seite weiterleiten, mit Hinweis, dass der Login dennoch erfolgreich war.
        }

        setPassword('');
        setStayLoggedIn(false);
        setFeedback('');
        setFeedbackClass(undefined);

        ionRouter.push('/', 'forward', 'replace', { unmount: true });
      } else {
        setFeedback('Fehlerhafte Zugangsdaten');
        setFeedbackClass('warning');
      }
    } else {
      switch (loginResult.statusCode) {
        case 401:
          setFeedback('Fehlerhafte Zugangsdaten');
          setFeedbackClass('warning');
          break;
        case 403:
          setFeedback('E-Mail-Adresse wurde nicht bestätigt');
          setFeedbackClass('warning');
          break;
        case 404:
          setFeedback('Benutzer nicht gefunden');
          setFeedbackClass('warning');
          break;
        case 429:
          setFeedback(
            'Zu viele Anfragen, versuche es erneut in ' +
              formatDuration(loginResult.retryAfter ?? 0)
          );
          setFeedbackClass('warning');
          break;
        default:
          setFeedback('Fehlerhafte Zugangsdaten');
          setFeedbackClass('warning');
          break;
      }
    }
  };

  return (
    <Layout backButton='/login/email' dontRefreshUserStateWhenLoggedIn>
      <div className='login-page login-page-password'>
        <span className='emoji'>🎉</span>
        <h2 className='mb-2'>Willkommen zurück!</h2>
        <h4 className='mb-4'>Wie lautet Dein Passwort?</h4>
        <form className='b1-form' onSubmit={submitPassword}>
          <input
            readOnly={true}
            type='text'
            autoComplete='username'
            value={registrationData.data.email}
            style={{ display: 'none' }}
          />
          <B1Input
            id='password'
            type='password'
            placeholder='Bitte Passwort eingeben'
            autoComplete='current-password'
            label='Passwort'
            onInput={(e) => setPassword((e.target as HTMLInputElement).value)}
            feedback={feedback}
            feedbackClass={feedbackClass}
          ></B1Input>
          <FormCheck
            id='stay_logged_in_2'
            onChange={(e) => setStayLoggedIn(e.currentTarget.checked)}
            label='Angemeldet bleiben'
          />
          <B1Button type='submit' className='button-block'>
            Weiter
          </B1Button>
        </form>
        <Link to='/login/forgot-password' className='forgot-password'>
          Passwort vergessen?
        </Link>
        {/* <div className='third-party-login-separator'>oder</div>
        <div className='third-party-login-buttons'>
          <B1Button className='button-block' type='button'>
            <IonImg src='assets/icons/google-logo.svg' />
            Mit Google anmelden
          </B1Button>
          <B1Button className='button-block' type='button'>
            <IonImg src='assets/icons/apple-logo.svg' />
            Mit Apple anmelden
          </B1Button>
        </div> */}
      </div>
    </Layout>
  );
};

export default LoginPassword;
