import { useIonRouter } from '@ionic/react';
import React from 'react';

import B1Button from '../../../components/buttons/B1Button';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import Layout from '../../Layout';

import '../../LoginRegister.scss';
import './RegisterDriverLicense.scss';

const RegisterDriverLicense: React.FC = () => {
  const [registrationData, dispatchRegistrationData] = useRegistrationDataState();
  const ionRouter = useIonRouter();

  const buttonClick = (hasDriverLicense: boolean) => {
    dispatchRegistrationData({
      type: 'SET_DATA_KEY',
      key: 'has_driver_license',
      value: hasDriverLicense,
    });

    ionRouter.push('/register/documents');
  };

  return (
    <Layout
      backButton={
        registrationData.code?.shipping_needed ? '/register/address' : '/register/personal-data'
      }
      dontRefreshUserStateWhenLoggedIn
    >
      <div className='register-page register-page-driver-license'>
        <h2 className='mb-3'>Vervollständige Dein Profil</h2>
        <h4>Bist Du im Besitz eines KFZ-Führerscheins?</h4>
        <div className='driver-license-buttons'>
          <B1Button className='button-block button-green' click={() => buttonClick(true)}>
            Ja
          </B1Button>
          <B1Button className='button-block button-red' click={() => buttonClick(false)}>
            Nein
          </B1Button>
        </div>
        <p>
          Solltest Du (noch) keinen KFZ-Führerschein besitzen, <br />
          benötigst Du ein Führungszeugnis für die <br />
          Prüfungsanmeldung.
        </p>
      </div>
    </Layout>
  );
};

export default RegisterDriverLicense;
