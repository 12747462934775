import { useIonRouter, useIonToast } from '@ionic/react';
import React, { FormEvent, useState } from 'react';

import { checkEmail } from '../../../api/account/email';
import { Prefill } from '../../../api/interfaces/account-info';
import B1Button from '../../../components/buttons/B1Button';
import B1Input from '../../../components/forms/B1Input';
import MigrationModal from '../../../components/modal/migration-modal/MigrationModal';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import { TRegistrationData } from '../../../reducers/registration-data-reducer';
import { isApiError } from '../../../utils/api-util';
import { validateEmail } from '../../../utils/misc-util';
import Layout from '../../Layout';

import '../../LoginRegister.scss';

const LoginEmail: React.FC = () => {
  const ionRouter = useIonRouter();
  const [presentToast] = useIonToast();

  const [registrationData, dispatchRegistrationData] = useRegistrationDataState();
  const [email, setEmail] = useState('');
  const [emailFeedback, setEmailFeedback] = useState('');
  const [migrationModalOpen, setMigrationModalOpen] = useState(false);

  async function submitEmail(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!email) {
      setEmailFeedback('Bitte gib Deine E-Mail-Adresse ein.');
      return;
    } else if (!validateEmail(email)) {
      setEmailFeedback('Ungültige E-Mail-Adresse.');
      return;
    }

    if (!registrationData.code) {
      presentToast({
        message: 'Es wurde kein Code eingegeben!',
        duration: 3000,
        position: 'bottom',
        color: 'danger',
      });
      ionRouter.push('/login/code', 'back', 'pop');
      return;
    }

    const emailResult = await checkEmail(registrationData.code.code, email);

    if (isApiError(emailResult)) {
      if (emailResult.statusCode === 400) {
        setEmailFeedback('Der eingegebene Code ist ungültig.');
      } else {
        setEmailFeedback('Fehler beim Überprüfen der E-Mail-Adresse.');
        console.error(emailResult);
      }
    } else {
      setEmailFeedback('');

      dispatchRegistrationData({ type: 'SET_DATA_KEY', key: 'email', value: email });

      if (emailResult.user_exists && !emailResult.user_has_password) {
        setMigrationModalOpen(true);
        return;
      }

      if (emailResult.user_exists) {
        ionRouter.push('/login/password');
      } else {
        if (emailResult.prefill?.email?.toLowerCase() === email.toLowerCase()) {
          for (const key of Object.keys(emailResult.prefill)) {
            const value = emailResult.prefill[key as keyof Prefill];

            if (!value) continue;

            dispatchRegistrationData({
              type: 'SET_DATA_KEY',
              key: key as TRegistrationData,
              value: value,
            });
          }
        }

        ionRouter.push('/register/password');
      }
    }
  }

  return (
    <Layout backButton='/login/code' dontRefreshUserStateWhenLoggedIn>
      <div className='login-page login-page-email'>
        <span className='emoji'>🎉</span>
        <h2 className='mb-2'>Willkommen!</h2>
        <h3 className='mb-4'>Kurszugang aktivieren</h3>
        {registrationData.code ? (
          <>
            <div className='b1-alert'>
              <img src='assets/icons/check-icon-filled-2.svg' />
              <p>
                Code für {registrationData.code.courses.length === 1 ? 'den Kurs ' : 'die Kurse '}
                {registrationData.code.courses.map((course) => course.title).join(' & ')} gültig
              </p>
            </div>
            <h4 className='mb-4'>Wie lautet Deine E-Mail-Adresse?</h4>
            <form className='b1-form' onSubmit={submitEmail}>
              <B1Input
                id='email'
                type='email'
                placeholder='Bitte E-Mail-Adresse eingeben'
                autoComplete='email'
                label='E-Mail-Adresse'
                onInput={(e) => setEmail((e.target as HTMLInputElement).value)}
                feedback={emailFeedback}
                feedbackClass={emailFeedback ? 'warning' : undefined}
              ></B1Input>
              <B1Button type='submit' className='button-block'>
                Weiter
              </B1Button>
            </form>
            {/* <div className='third-party-login-separator'>oder</div>
            <div className='third-party-login-buttons'>
              <B1Button className='button-block' type='button'>
                <IonImg src='assets/icons/google-logo.svg' />
                Mit Google anmelden
              </B1Button>
              <B1Button className='button-block' type='button'>
                <IonImg src='assets/icons/apple-logo.svg' />
                Mit Apple anmelden
              </B1Button>
            </div> */}
          </>
        ) : (
          <>
            <div className='b1-alert'>
              <img src='assets/icons/cross-circle.svg' />
              <p>Kein Code gefunden.</p>
            </div>
            <B1Button className='button-block' href='/login/code' routerDirection='back'>
              Zurück zur Code-Eingabe
            </B1Button>
          </>
        )}
      </div>
      <MigrationModal open={migrationModalOpen} setOpen={setMigrationModalOpen} email={email} />
    </Layout>
  );
};

export default LoginEmail;
