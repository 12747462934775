import { isApiError } from '../utils/api-util';
import { makeApiRequest } from './api';
import { ClaimedSpinPrize, WheelSpinPrizeOption, WheelSpins } from './interfaces/wheel-spins';

export const getWheelSpins = async () => {
  const res = await makeApiRequest<WheelSpins>('GET', 'v1/wheel-spins');

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const getWheelSpinPrizes = async () => {
  const res = await makeApiRequest<WheelSpinPrizeOption[]>('GET', 'v1/wheel-spins/prizes');

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const spinWheel = async (id: number) => {
  const res = await makeApiRequest<ClaimedSpinPrize>('POST', `v1/wheel-spins/${id}`);

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const redeemWheelSpin = async (
  id: number,
  size: number | undefined,
  color: number | undefined
) => {
  const res = await makeApiRequest('POST', `v1/wheel-spins/${id}/redeem`, undefined, {
    size_id: size,
    color_id: color,
  });

  if (isApiError(res)) {
    return res;
  }

  return res.status === 200;
};
