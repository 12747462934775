import { useIonToast } from '@ionic/react';
import { DateTime } from 'luxon';
import React from 'react';

import { TripRegion as ITripRegion, Trip, TripAvailability } from '../../../../api/interfaces/trip';

import './TripRegion.scss';

interface TripRegionProps {
  tripRegion: ITripRegion;
  onTripSelected: (trip: Trip) => void;
  hasTripRequested: boolean;
}

const TripRegion: React.FC<TripRegionProps> = ({
  tripRegion,
  onTripSelected,
  hasTripRequested,
}) => {
  const [presentToast] = useIonToast();

  const getAvailabilityClass = (trip: Trip) => {
    const classes = ['trip-region__trip__availability'];

    switch (trip.availability) {
      case TripAvailability.HIGH:
        classes.push('trip-region__trip__availability--high');
        break;
      case TripAvailability.MEDIUM:
        classes.push('trip-region__trip__availability--medium');
        break;
      case TripAvailability.LOW:
        classes.push('trip-region__trip__availability--low');
        break;
    }

    return classes.join(' ');
  };

  const getAvailabilityDescription = (trip: Trip) => {
    switch (trip.availability) {
      case TripAvailability.HIGH:
        return 'Verfügbar';
      case TripAvailability.MEDIUM:
        return 'Wenige Plätze';
      case TripAvailability.LOW:
        return 'Ausgebucht';
    }
  };

  const handleTripSelected = (trip: Trip) => {
    if (hasTripRequested) {
      presentToast({
        message: 'Du hast bereits eine Törnanfrage in dieser Kategorie gestellt.',
        duration: 3000,
        position: 'bottom',
        color: 'danger',
      });
      return;
    } else if (trip.availability === TripAvailability.LOW) {
      presentToast({
        message: 'Dieser Törn ist leider ausgebucht.',
        duration: 3000,
        position: 'bottom',
        color: 'danger',
      });
      return;
    }

    onTripSelected(trip);
  };

  return (
    <div
      className='trip-region mt-3 mb-3'
      style={
        {
          '--background-image': tripRegion.picture_link && `url('${tripRegion.picture_link}')`,
        } as React.CSSProperties
      }
    >
      <div className='trip-region__main-content'>
        <h5 className='mb-3'>{tripRegion.name}</h5>
        <div className='trip-region__trips'>
          {tripRegion.trips.map((trip, index) => (
            <div
              key={index}
              className='trip-region__trip scale-effect'
              tabIndex={0}
              onClick={() => handleTripSelected(trip)}
            >
              <div className={getAvailabilityClass(trip)}>{getAvailabilityDescription(trip)}</div>
              <div className='trip-region__trip__date'>
                <p>{DateTime.fromISO(trip.start_date).toFormat('dd.MM.')} –</p>
                {/* <p>–</p> */}
                <p>{DateTime.fromISO(trip.end_date).toFormat('dd.MM.')}</p>
              </div>
              {trip.trip_request && <p className='trip-region__trip__requested mt-2'>Angefragt</p>}
            </div>
          ))}
        </div>
      </div>
      <div className='trip-region__starting-price'>
        <p className='mb-3'>ab</p>
        <p className='trip-region__starting-price__price'>
          {new Intl.NumberFormat(navigator.language, { style: 'currency', currency: 'EUR' }).format(
            tripRegion.starting_price
          )}
        </p>
      </div>
    </div>
  );
};

export default TripRegion;
