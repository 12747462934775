import React, { useContext } from 'react';
import { Snowfall as ReactSnowfall } from 'react-snowfall';

import useWindowSize from '../../hooks/window-size-hook';
import { AuthContext } from '../../providers/AuthProvider';

const Snowfall: React.FC = () => {
  const { snowfallEnabled } = useContext(AuthContext);
  const { width } = useWindowSize();

  let snowflakeCount = 150;

  if (!snowfallEnabled) return <></>;

  if (width && width < 768) {
    snowflakeCount = 75;
  }

  return <ReactSnowfall snowflakeCount={snowflakeCount} style={{ zIndex: 0 }} />;
};

export default Snowfall;
