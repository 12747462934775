import { Animation, createAnimation, createGesture } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';

import { createEvent } from '../../api/account/account';
import CustomLink from '../custom-link/CustomLink';
import B1Modal from '../modal/B1Modal';

import './EasterEgg.scss';

const EasterEgg: React.FC = () => {
  const DOUBLE_CLICK_THRESHOLD = 1000;
  const ref = useRef<HTMLParagraphElement>(null);
  const heartRef = useRef<HTMLSpanElement>(null);
  const heartAnimation = useRef<Animation>();
  const [modalOpen, setModalOpen] = useState(false);

  let lastOnStart = 0;
  let clicks = 0;

  const onStart = () => {
    const now = Date.now();

    clicks++;

    heartAnimation.current?.play();

    if (Math.abs(now - lastOnStart) <= DOUBLE_CLICK_THRESHOLD) {
      lastOnStart = now;

      if (clicks === 8 && ref.current) {
        setModalOpen(true);
        clicks = 0;
        createEvent('easteregg_clicked');
      }
    } else {
      clicks = 1;
      lastOnStart = now;
    }
  };

  useEffect(() => {
    if (!heartAnimation.current && heartRef.current) {
      heartAnimation.current = createAnimation()
        .addElement(heartRef.current)
        .fill('none')
        .duration(500)
        .keyframes([
          { offset: 0, transform: 'scale(1)', opacity: '1' },
          { offset: 0.5, transform: 'scale(1.5)', opacity: '0.5' },
          { offset: 1, transform: 'scale(1)', opacity: '1' },
        ]);
    }

    if (ref.current) {
      const gesture = createGesture({
        el: ref.current,
        threshold: 0,
        onStart,
        gestureName: 'double-click',
      });

      gesture.enable();
    }
  }, [ref, heartRef]);

  return (
    <>
      <p ref={ref}>
        Mit <span ref={heartRef}>❤️</span> erstellt von Bootsschule1&reg;
      </p>
      <B1Modal
        open={modalOpen}
        onModalClose={() => setModalOpen(false)}
        className='easter-egg-modal'
      >
        <div className='developers'>
          <img
            className='developer'
            src='assets/images/avatar-leon.webp'
            alt='Leon Burger'
            title='Leon Burger'
          />
          <img
            className='developer'
            src='assets/images/avatar-martin.webp'
            alt='Martin Frühauf'
            title='Martin Frühauf'
          />
        </div>
        <p className='mt-5'>
          Entwickelt mit ❤️ von
          <br />
          Leon Burger und{' '}
          <CustomLink href='https://www.linkedin.com/in/martin-fruehauf/'>
            Martin Frühauf
          </CustomLink>
          <br />
          in Leipzig.
        </p>
      </B1Modal>
    </>
  );
};

export default EasterEgg;
