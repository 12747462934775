import React, { useContext, useEffect, useState } from 'react';

import { changePersonalData } from '../../../api/account/contact-data';
import { AccountStatus } from '../../../api/interfaces/account-info';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import { AuthContext } from '../../../providers/AuthProvider';
import {
  PersonalData,
  RegistrationData,
  RegistrationDataAction,
  RegistrationDataStateType,
  registrationDataReducer,
} from '../../../reducers/registration-data-reducer';
import { isApiError } from '../../../utils/api-util';
import B1Button from '../../buttons/B1Button';
import PersonalDataForm from '../../forms/personal-data-form/PersonalDataForm';
import B1Modal from '../B1Modal';

import './ChangePersonalDataModal.scss';

interface ChangePersonalDataModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  submitButtonText?: string;
  onPersonalDataChanged: () => void;
}

const ChangePersonalDataModal: React.FC<ChangePersonalDataModalProps> = ({
  open,
  setOpen,
  submitButtonText,
  onPersonalDataChanged,
}) => {
  const [registrationData, dispatchRegistrationData] = useRegistrationDataState();
  const { isAuthenticated, getUserData } = useContext(AuthContext);
  const [userState, setUserState] = useState<AccountStatus>();
  const [mode, setMode] = useState<'personal-data' | 'driver_license'>('personal-data');

  useEffect(() => {
    getUserData().then(setUserState);
  }, [isAuthenticated, getUserData]);

  useEffect(() => {
    dispatchRegistrationData({
      type: 'SET_PERSONAL_DATA',
      personalData: {
        nationality_code: userState?.nationality_code ?? '',
        date_of_birth: userState?.birth_date ?? null,
        birth_place: userState?.birth_place ?? '',
        sex: (userState?.sex as 'm' | 'w' | 'd') ?? null,
        has_driver_license: userState?.has_driver_license,
      },
    });
  }, [userState]);

  const handleSubmitPersonalData = () => {
    setMode('driver_license');
  };

  const handleDriverLicenseButtonClick = async (hasDriverLicense: boolean) => {
    const action: RegistrationDataAction = {
      type: 'SET_DATA_KEY',
      key: 'has_driver_license',
      value: hasDriverLicense,
    };
    await dispatchRegistrationData(action);

    const nextState = registrationDataReducer(registrationData, action);

    submitPersonalData(nextState);
  };

  const submitPersonalData = async (registrationData: RegistrationDataStateType) => {
    const data = { ...registrationData.data };

    Object.keys(data).forEach((key) => {
      const x = key as keyof RegistrationData;

      const isPersonalDataField = (field: keyof RegistrationData): field is keyof PersonalData => {
        return (
          field === 'nationality_code' ||
          field === 'date_of_birth' ||
          field === 'birth_place' ||
          field === 'sex' ||
          field === 'has_driver_license'
        );
      };

      if (!isPersonalDataField(x)) {
        delete data[x];
      }
    });

    const result = await changePersonalData(data);

    if (isApiError(result)) {
      console.error(result);
      return;
    }

    onPersonalDataChanged();
  };

  useEffect(() => {
    if (open) {
      setMode('personal-data');
    }
  }, [open]);

  return (
    <B1Modal
      open={open}
      onModalClose={() => setOpen(false)}
      className='change-personal-data-modal'
      enableAutoHeight
    >
      <h1 className='emoji'>🪪</h1>
      {mode === 'personal-data' && (
        <>
          <h2 className='no-hyphens'>Stimmen Deine persönlichen Daten noch?</h2>
          <p className='mb-4'>Bitte überprüfe Deine Daten und ergänze ggf. fehlende Angaben.</p>
          <PersonalDataForm
            hideNameInfo={true}
            submitButtonText={submitButtonText}
            onSubmit={handleSubmitPersonalData}
          />
        </>
      )}
      {mode === 'driver_license' && (
        <>
          <h2>Bist Du im Besitz eines KFZ-Führerscheins?</h2>
          <div className='driver-license-buttons'>
            <B1Button
              className='button-block button-green'
              click={() => handleDriverLicenseButtonClick(true)}
            >
              Ja
            </B1Button>
            <B1Button
              className='button-block button-red'
              click={() => handleDriverLicenseButtonClick(false)}
            >
              Nein
            </B1Button>
          </div>
          <p>
            Solltest Du (noch) keinen KFZ-Führerschein besitzen, <br />
            benötigst Du ein Führungszeugnis für die <br />
            Prüfungsanmeldung.
          </p>
        </>
      )}
    </B1Modal>
  );
};

export default ChangePersonalDataModal;
