import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import * as SentryCapacitor from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { initializeApp } from 'firebase/app';
import { createBrowserHistory } from 'history';
import Hls from 'hls.js';
import React from 'react';
import { pdfjs } from 'react-pdf';
import { Redirect, Route } from 'react-router-dom';
import { useRegisterSW } from 'virtual:pwa-register/react';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* React-PDF styles */
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

/* Theme variables */
import './theme/variables.css';
// import './theme/dark-variables.css';
/* Fonts */
import './theme/fonts.css';
/* Global styles */
import './theme/global.scss';

import AuthProvider from './providers/AuthProvider';
import RegistrationDataProvider from './providers/RegistrationDataProvider';
import { initFirebaseMessaging } from './utils/firebase-utils';
import { createStorage, getStorage } from './utils/storage';

import AppUrlListener from './AppUrlListener';
import FirebaseMessagingListener from './FirebaseMessagingListener';
import IonRouterOutletPatchedWithForwardedRef from './components/ion-router-outlet-patched/IonRouterOutletPatched';
import Snowfall from './components/snowfall/Snowfall';
import AuthComponent from './routes/AuthComponent';

import Home from './pages/Home';

import Login from './pages/login/Login';
import LoginCode from './pages/login/code/LoginCode';
import LoginEmail from './pages/login/email/LoginEmail';
import LoginForgotPassword from './pages/login/forgot-password/LoginForgotPassword';
import LoginPassword from './pages/login/password/LoginPassword';
import LoginResetPassword from './pages/login/reset-password/LoginResetPassword';

import RegisterAddress from './pages/register/address/RegisterAddress';
import RegisterDocuments from './pages/register/documents/RegisterDocuments';
import RegisterDriverLicense from './pages/register/driver-license/RegisterDriverLicense';
import RegisterPassword from './pages/register/password/RegisterPassword';
import RegisterPersonalData from './pages/register/personal-data/RegisterPersonalData';
import RegisterSuccess from './pages/register/success/RegisterSuccess';

import Account from './pages/account/Account';
import AddressChange from './pages/account/AddressChange';
import ChangeEmail from './pages/account/ChangeEmail';
import ChangePassword from './pages/account/ChangePassword';
import ConfirmEmail from './pages/account/ConfirmEmail';
import RedeemCode from './pages/account/RedeemCode';

import BSPDashboard from './pages/courses/bsp/BSPDashboard';
import ExamBookingBSP from './pages/courses/bsp/exam-booking/ExamBookingBSP';
import BSPDDashboard from './pages/courses/bspd/BSPDDashboard';
import FKNDashboard from './pages/courses/fkn/FKNDashboard';
import ExamBookingFKN from './pages/courses/fkn/exam-booking/ExamBookingFKN';
import NavigationQuestions from './pages/courses/nvg/navigation-training/NavigationQuestions';
import NavigationTraining from './pages/courses/nvg/navigation-training/NavigationTraining';
import PartnerSBFDashboard from './pages/courses/partner-courses/PartnerSBFDashboard';
import PartnerSRCDashboard from './pages/courses/partner-courses/PartnerSRCDashboard';
import PartnerUBIDashboard from './pages/courses/partner-courses/PartnerUBIDashboard';
import SBFDashboard from './pages/courses/sbf/SBFDashboard';
import PracticalTraining from './pages/courses/sbf/practical-training/PracticalTraining';
import SBFBSDashboard from './pages/courses/sbfbs/SBFBSDashboard';
import SKNDashboard from './pages/courses/skn/SKNDashboard';
import SKSDashboard from './pages/courses/sks/SKSDashboard';
import Trips from './pages/courses/sks/trips/Trips';
import SRCDashboard from './pages/courses/src/SRCDashboard';
import ExamBookingSRC from './pages/courses/src/exam-booking/ExamBookingSRC';
import UBIDashboard from './pages/courses/ubi/UBIDashboard';
import ExamBookingUBI from './pages/courses/ubi/exam-booking/ExamBookingUBI';
import ExamBookingMap from './pages/exam-booking-map/ExamBookingMap';
import ExamPaper from './pages/exam-simulator/ExamPaper';
import ExamSimulator from './pages/exam-simulator/ExamSimulator';
import Gluecksrad from './pages/gluecksrad/Gluecksrad';
import Knots from './pages/knots/Knots';
import ChapterContentPage from './pages/learnmode/ChapterContentPage';
import ChapterQuiz from './pages/learnmode/ChapterQuiz';
import OralExamPractice from './pages/radio-oral-exam-practice/OralExamPractice';
import RadioPracticalExercises from './pages/radio-practical-exercises/RadioPracticalExercises';
import RadioEmbed from './pages/radio/RadioEmbed';
import Smartmode from './pages/smartmode/Smartmode';
import SmartmodeQuiz from './pages/smartmode/SmartmodeQuiz';

setupIonicReact();
createStorage();

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url
).toString();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).Hls = Hls;

if (Capacitor.getPlatform() === 'android') {
  StatusBar.setStyle({ style: Style.Dark });
  StatusBar.setBackgroundColor({ color: '#04165c' });
  // Folgende, eigentlich bessere Variante ist aktuell in Chromium verbuggt, siehe:
  //https://bugs.chromium.org/p/chromium/issues/detail?id=1094366 und https://github.com/ionic-team/capacitor/issues/2840
  // StatusBar.setOverlaysWebView({ overlay: true });

  // Respektiert auch keine Safe-Areas:
  // NavigationBar.setTransparency({ isTransparent: true });

  // CapacitorApp.addListener('resume', () => {
  //   NavigationBar.setTransparency({ isTransparent: true });
  // });
}

const SentryRoute = SentryReact.withSentryRouting(Route);

const history = createBrowserHistory();

const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;

if (SENTRY_DSN) {
  SentryCapacitor.init(
    {
      dsn: SENTRY_DSN,
      // To set your release and dist versions
      release: 'bootsschule1-frontend@' + APP_VERSION,
      dist: '1',

      // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
      // We recommend adjusting this value in production.
      tracesSampleRate: 0.5,

      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.01,

      // If the entire session is not sampled, use the below sample rate to sample
      // sessions when an error occurs.
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        SentryReact.reactRouterV5BrowserTracingIntegration({ history }),
        SentryReact.replayIntegration({
          maskAllInputs: true,
          maskAllText: false,
        }),
      ],

      // Set "tracePropagationTargets" to control for which URLs distributed tracing should be enabled
      // tracePropagationTargets: ['localhost', API_URL],
    },
    // Forward the init method to the React Framework.
    SentryReact.init
  );
}

if (Capacitor.getPlatform() === 'web') {
  // Initialization for @capacitor-community/firebase-analytics plugin
  FirebaseAnalytics.initializeFirebase({
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  }).then(() => {
    FirebaseAnalytics.setCollectionEnabled({ enabled: true });
  });

  // Initialization for @capacitor-firebase plugins
  initializeApp({
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  });
} else {
  FirebaseAnalytics.setCollectionEnabled({ enabled: true });
}

initFirebaseMessaging();

const App: React.FC = () => {
  useRegisterSW({
    immediate: true,
    onRegisteredSW: async (_swScriptUrl, registration) => {
      if (registration) {
        setInterval(
          () => {
            registration.update();
          },
          10 * 60 * 1000 // check every 10 minutes
        );
      }
    },
    onRegisterError: (err) => {
      console.error('Error during service worker registration:', err);
    },
  });

  const storage = getStorage();

  storage.get('version').then((/* value */) => {
    // TODO: Show changelog?

    storage.set('version', APP_VERSION);
  });

  return (
    <IonApp>
      <AuthProvider>
        <RegistrationDataProvider>
          <IonReactRouter history={history}>
            <AppUrlListener />
            <FirebaseMessagingListener />
            <Snowfall />
            {/*
              Hinweis: Fallback-Route (am Ende) funktioniert nicht richtig mit dem Standard-IonRouterOutlet,
              deshalb hier der Workaround. Mehr Infos: https://github.com/ionic-team/ionic-framework/issues/23743
            */}
            <IonRouterOutletPatchedWithForwardedRef
              animated={!document.documentElement.classList.contains('plt-desktop')}
            >
              <SentryRoute exact path='/'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <Home />
                </AuthComponent>
              </SentryRoute>

              {/*
                Untergeordnete Routes funktionieren leider nicht (richtig).
                <SentryRoute path='/login' component={LoginRoutes} />
                <SentryRoute path='/register' component={RegisterRoutes} />
                Further reading:
                https://github.com/ionic-team/ionic-framework/issues/20363
                https://stackoverflow.com/questions/73450464/react-nested-routes-default-path-blank-page
                https://forum.ionicframework.com/t/ionic-react-so-many-render-issues-related-to-routing/222301
                https://forum.ionicframework.com/t/ionic-react-nested-routes/194055
              */}

              <SentryRoute exact path='/login'>
                <Login />
              </SentryRoute>
              <SentryRoute exact path='/login/code/:code?'>
                <LoginCode />
              </SentryRoute>
              <SentryRoute exact path='/login/email'>
                <LoginEmail />
              </SentryRoute>
              <SentryRoute exact path='/login/password'>
                <LoginPassword />
              </SentryRoute>
              <SentryRoute exact path='/login/forgot-password/:email?'>
                <LoginForgotPassword />
              </SentryRoute>
              <SentryRoute exact path='/login/reset-password/:token'>
                <LoginResetPassword />
              </SentryRoute>

              <SentryRoute exact path='/register/password'>
                <RegisterPassword />
              </SentryRoute>
              <SentryRoute exact path='/register/personal-data'>
                <RegisterPersonalData />
              </SentryRoute>
              <SentryRoute exact path='/register/address'>
                <RegisterAddress />
              </SentryRoute>
              <SentryRoute exact path='/register/driver-license'>
                <RegisterDriverLicense />
              </SentryRoute>
              <SentryRoute exact path='/register/documents'>
                <RegisterDocuments />
              </SentryRoute>
              <SentryRoute exact path='/register/success'>
                <RegisterSuccess />
              </SentryRoute>

              <SentryRoute exact path='/account'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <Account />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/account/change-password'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ChangePassword />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/account/change-email'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ChangeEmail />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/account/redeem-code/:code?'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <RedeemCode />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/account/address-change'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <AddressChange />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/account/confirm-email/:token'>
                <ConfirmEmail />
              </SentryRoute>

              <SentryRoute exact path='/gluecksrad'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <Gluecksrad />
                </AuthComponent>
              </SentryRoute>

              {/* TODO: Delete when modifying back links working on ios */}
              <SentryRoute exact path='/course/sbfs'>
                <Redirect to='/course/sbf' />
              </SentryRoute>
              <SentryRoute exact path='/course/sbfb'>
                <Redirect to='/course/sbf' />
              </SentryRoute>
              <SentryRoute exact path='/course/sbfn'>
                <Redirect to='/course/sbf' />
              </SentryRoute>
              <SentryRoute exact path='/course/sbfbb'>
                <Redirect to='/course/sbf' />
              </SentryRoute>
              <SentryRoute exact path='/course/sbfbsp'>
                <Redirect to='/course/sbfbs' />
              </SentryRoute>
              <SentryRoute exact path='/course/srcp'>
                <Redirect to='/course/src' />
              </SentryRoute>
              <SentryRoute exact path='/course/ubip'>
                <Redirect to='/course/ubi' />
              </SentryRoute>

              {/* Partner courses */}
              <SentryRoute exact path='/course/psbfs'>
                <Redirect to='/course/psbf' />
              </SentryRoute>
              <SentryRoute exact path='/course/psbfb'>
                <Redirect to='/course/psbf' />
              </SentryRoute>
              <SentryRoute exact path='/course/psbfn'>
                <Redirect to='/course/psbf' />
              </SentryRoute>
              <SentryRoute exact path='/course/psbfbb'>
                <Redirect to='/course/psbf' />
              </SentryRoute>
              <SentryRoute exact path='/course/pubip'>
                <Redirect to='/course/pubi' />
              </SentryRoute>

              <SentryRoute exact path='/course/sbf'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <SBFDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/sbfbs'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <SBFBSDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/bsp'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <BSPDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/bspd'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <BSPDDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/src'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <SRCDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/ubi'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <UBIDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/sks'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <SKSDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/fkn'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <FKNDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/skn'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <SKNDashboard />
                </AuthComponent>
              </SentryRoute>

              {/* Partner courses */}
              <SentryRoute exact path='/course/psbf'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <PartnerSBFDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/psrc'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <PartnerSRCDashboard />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/pubi'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <PartnerUBIDashboard />
                </AuthComponent>
              </SentryRoute>

              <SentryRoute exact path='/course/:identifier/practical-training'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <PracticalTraining />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier(sbfs|sbfb|sks|skn)/exam-booking'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ExamBookingMap />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/bsp/exam-booking'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ExamBookingBSP />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/src/exam-booking'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ExamBookingSRC />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/ubi/exam-booking'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ExamBookingUBI />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/fkn/exam-booking'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ExamBookingFKN />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier(src|ubi|psrc|pubi)/radio'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <RadioEmbed />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier(src|ubi)/oral-exam-practice'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <OralExamPractice />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute
                exact
                path='/course/:identifier(src|ubi)/radio-practical-exercises/:exerciseId?'
              >
                <AuthComponent showLoadingText redirectTo='/login'>
                  <RadioPracticalExercises />
                </AuthComponent>
              </SentryRoute>

              <SentryRoute exact path='/course/:identifier/learnmode/:chapterId?'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ChapterContentPage />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier/learnmode/:chapterId/quiz'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ChapterQuiz />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier/knots'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <Knots />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier/smartmode'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <Smartmode />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier/smartmode/:lessonId'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <SmartmodeQuiz />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier/exam-training'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ExamSimulator />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier/exam-training/:examPaperId'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <ExamPaper />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier/navigation-training'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <NavigationTraining />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/:identifier/navigation-training/:exerciseId'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <NavigationQuestions />
                </AuthComponent>
              </SentryRoute>
              <SentryRoute exact path='/course/sks/trips'>
                <AuthComponent showLoadingText redirectTo='/login'>
                  <Trips />
                </AuthComponent>
              </SentryRoute>

              <SentryRoute>
                <Redirect to='/' />
              </SentryRoute>
            </IonRouterOutletPatchedWithForwardedRef>
          </IonReactRouter>
        </RegistrationDataProvider>
      </AuthProvider>
      <div className='background-ellipse-left'></div>
      <div className='background-ellipse-dark'></div>
      <div className='background-ellipse-light'></div>
    </IonApp>
  );
};

export default App;
