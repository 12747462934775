import { useIonToast } from '@ionic/react';
import React from 'react';

import B1Modal from '../B1Modal';
import FeedbackForm from './FeedbackForm';

import './FeedbackModal.scss';

interface FeedbackModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const FeedbackModal: React.FC<FeedbackModalProps> = ({ open, setOpen }) => {
  const [presentToast] = useIonToast();

  return (
    <B1Modal className='feedback-modal' open={open} onModalClose={() => setOpen(false)}>
      <h1 className='emoji'>🙋</h1>
      <h2>Feedback</h2>
      <FeedbackForm
        onFeedbackSent={() => {
          setOpen(false);

          presentToast({
            message: 'Vielen Dank für Dein Feedback!',
            duration: 3000,
            position: 'bottom',
            color: 'success',
          });
        }}
      />
    </B1Modal>
  );
};

export default FeedbackModal;
