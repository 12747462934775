import { IonContent, IonFooter, IonModal, IonToolbar, useIonViewWillLeave } from '@ionic/react';
import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { BiChevronDown } from 'react-icons/bi';

import './B1Modal.scss';

interface ContentWrapperProps extends PropsWithChildren {
  mode?: 'ios' | 'md';
  presentingElement?: HTMLElement;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ mode, presentingElement, children }) => {
  if (mode === 'ios' && presentingElement) {
    return <IonContent>{children}</IonContent>;
  } else {
    return <>{children}</>;
  }
};

interface B1ModalProps extends PropsWithChildren, React.HTMLAttributes<HTMLIonModalElement> {
  open: boolean;
  onModalClose: () => void;
  onModalOpen?: () => void;
  onModalWillClose?: () => void;
  onModalWillOpen?: () => void;
  topContent?: React.ReactNode;
  footer?: React.ReactNode;
  backdropDismiss?: boolean;
  mode?: 'ios' | 'md';
  presentingElement?: HTMLElement;
  enableAutoHeight?: boolean;
  hideCloseButton?: boolean;
  showFooterCloseButton?: boolean;
}

const B1Modal: React.FC<B1ModalProps> = ({
  open: modalOpen,
  onModalClose,
  onModalOpen,
  onModalWillClose,
  onModalWillOpen,
  topContent,
  children,
  backdropDismiss = true,
  enableAutoHeight = false,
  hideCloseButton = false,
  showFooterCloseButton = false,
  footer,
  ...props
}) => {
  const modal = useRef<HTMLIonModalElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [autoHeight, setAutoHeight] = React.useState<boolean>(false);

  useEffect(() => {
    if (modalOpen) handleModalSize();
  }, [modalOpen, modal.current?.offsetHeight, contentRef.current?.offsetHeight]);

  useIonViewWillLeave(() => {
    onModalClose();
  }, []);

  const handleModalOpen = () => {
    if (onModalOpen) onModalOpen();

    handleModalSize();
  };

  const handleModalSize = () => {
    if (!enableAutoHeight) return;

    const modalHeight = modal.current?.offsetHeight;
    const containerHeight = contentRef.current?.offsetHeight;

    if (modalHeight !== undefined && containerHeight !== undefined) {
      setAutoHeight(modalHeight < containerHeight);
    }
  };

  const getClassNames = () => {
    const classNames = [];

    if (props.mode === 'ios' && props.presentingElement) {
      classNames.push('b1-sheet-modal');
    } else {
      classNames.push('b1-modal');
    }

    if (props.className) {
      classNames.push(props.className);
    }

    if (autoHeight) {
      classNames.push('auto-height');
    }

    return classNames.join(' ');
  };

  return (
    <IonModal
      {...props}
      ref={modal}
      className={getClassNames()}
      isOpen={modalOpen}
      onIonModalDidDismiss={onModalClose}
      onIonModalDidPresent={handleModalOpen}
      onIonModalWillDismiss={onModalWillClose}
      onIonModalWillPresent={onModalWillOpen}
      backdropDismiss={backdropDismiss}
    >
      <ContentWrapper mode={props.mode} presentingElement={props.presentingElement}>
        {topContent}
        {!hideCloseButton && (
          <button
            type='button'
            className='close-modal-top-button scale-effect'
            onClick={onModalClose}
          >
            <img src='assets/icons/close-icon-grey.svg' />
          </button>
        )}
        <div className='modal-container custom-scrollbar'>
          <div ref={contentRef} className='modal-content'>
            {children}
          </div>
        </div>
      </ContentWrapper>
      {footer && <IonFooter>{footer}</IonFooter>}
      {showFooterCloseButton && (
        <IonFooter>
          <IonToolbar className='close-modal-footer'>
            <button type='button' onClick={onModalClose} className='close-modal-footer-button'>
              <span>Schließen</span>
              <BiChevronDown />
            </button>
          </IonToolbar>
        </IonFooter>
      )}
    </IonModal>
  );
};

export default B1Modal;
