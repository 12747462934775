import { useIonToast } from '@ionic/react';
import { TCountryCode, getEmojiFlag } from 'countries-list';
import React, { FormEvent, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { checkAddress } from '../../../api/account/address';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import PhoneNumber from '../../../pages/register/components/PhoneNumber';
import { RegistrationData } from '../../../reducers/registration-data-reducer';
import { isApiError } from '../../../utils/api-util';
import { getCountryOptions, getPhoneNumberPrefixes } from '../../../utils/register-util';
import B1Button from '../../buttons/B1Button';
import Twemoji from '../../twemoji/Twemoji';
import B1Input from '../B1Input';

import './AddressForm.scss';

interface AddressFormProps {
  hideDeliveryInfo?: boolean;
  submitButtonText?: string;
  onSubmit: () => void;
}

const AddressForm: React.FC<AddressFormProps> = ({
  hideDeliveryInfo,
  submitButtonText,
  onSubmit,
}) => {
  const [presentToast] = useIonToast();

  const [registrationData, dispatchRegistrationData] = useRegistrationDataState();
  const [streetFeedback, setStreetFeedback] = useState(false);
  const [houseNbrFeedback, setHouseNbrFeedback] = useState(false);
  const [zipFeedback, setZipFeedback] = useState(false);
  const [placeFeedback, setPlaceFeedback] = useState(false);
  const [countryFeedback, setCountryFeedback] = useState(false);
  const [phoneNumberFeedback, setPhoneNumberFeedback] = useState<string>();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setStreetFeedback(false);
    setHouseNbrFeedback(false);
    setZipFeedback(false);
    setPlaceFeedback(false);
    setCountryFeedback(false);

    let incomplete = false;

    if (!registrationData.data.street) {
      setStreetFeedback(true);
      incomplete = true;
    }
    if (!registrationData.data.house_nbr) {
      setHouseNbrFeedback(true);
      incomplete = true;
    }
    if (!registrationData.data.zip) {
      setZipFeedback(true);
      incomplete = true;
    }
    if (!registrationData.data.place) {
      setPlaceFeedback(true);
      incomplete = true;
    }
    if (!registrationData.data.country_code) {
      setCountryFeedback(true);
      incomplete = true;
    }
    if (!registrationData.data.phone_number) {
      if (!phoneNumberFeedback) {
        setPhoneNumberFeedback('Bitte gib Deine Telefonnummer an.');
      }
      incomplete = true;
    }

    if (incomplete) return;

    const addressCheckResult = await checkAddress({
      street: registrationData.data.street,
      house_nbr: registrationData.data.house_nbr,
      zip: registrationData.data.zip,
      place: registrationData.data.place,
      country_code: registrationData.data.country_code,
    });

    if (isApiError(addressCheckResult)) {
      if (addressCheckResult.statusCode === 400) {
        presentToast({
          message: 'Die eingegebene Adresse wurde leider nicht gefunden.',
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        });
        return;
      }

      presentToast({
        message:
          'Beim Überprüfen der Adresse ist ein Fehler aufgetreten.\n' +
          'Bitte versuche es später erneut oder melde Dich bei uns.',
        duration: 5000,
        position: 'bottom',
        color: 'danger',
      });
      console.error(addressCheckResult);
      return;
    }

    if (
      addressCheckResult.street !== registrationData.data.street ||
      addressCheckResult.house_nbr !== registrationData.data.house_nbr ||
      addressCheckResult.zip !== registrationData.data.zip ||
      addressCheckResult.place !== registrationData.data.place ||
      addressCheckResult.country_code !== registrationData.data.country_code
    ) {
      dispatchRegistrationData({
        type: 'SET_DATA_KEY',
        key: 'street',
        value: addressCheckResult.street,
      });
      dispatchRegistrationData({
        type: 'SET_DATA_KEY',
        key: 'house_nbr',
        value: addressCheckResult.house_nbr,
      });
      dispatchRegistrationData({ type: 'SET_DATA_KEY', key: 'zip', value: addressCheckResult.zip });
      dispatchRegistrationData({
        type: 'SET_DATA_KEY',
        key: 'place',
        value: addressCheckResult.place,
      });
      dispatchRegistrationData({
        type: 'SET_DATA_KEY',
        key: 'country_code',
        value: addressCheckResult.country_code,
      });

      presentToast({
        message: 'Die Adresse wurde angepasst, bitte überprüfe sie noch einmal.',
        duration: 3000,
        position: 'bottom',
        color: 'warning',
      });
      return;
    }

    onSubmit();
  };

  const setValue = (
    key: keyof RegistrationData,
    event: React.FormEvent<HTMLInputElement | HTMLSelectElement> | string
  ) => {
    let value;

    if (typeof event === 'string') {
      value = event;
    } else {
      value = (event.target as HTMLInputElement).value;
    }

    if (value) {
      switch (key) {
        case 'street':
          setStreetFeedback(false);
          break;
        case 'house_nbr':
          setHouseNbrFeedback(false);
          break;
        case 'zip':
          setZipFeedback(false);
          break;
        case 'place':
          setPlaceFeedback(false);
          break;
        case 'country_code':
          setCountryFeedback(false);
          break;
      }
    }

    dispatchRegistrationData({
      type: 'SET_DATA_KEY',
      key: key,
      value: value,
    });
  };

  return (
    <form className='b1-form address-form' onSubmit={handleSubmit}>
      <Row>
        <Col sm='9'>
          <B1Input
            id='street'
            type='text'
            placeholder=''
            autoComplete='street-address'
            label='Straße'
            value={registrationData.data.street}
            onInput={(e) => setValue('street', e)}
            feedback={streetFeedback ? 'Bitte gib Deine Straße an.' : undefined}
            feedbackClass={streetFeedback ? 'warning' : undefined}
          />
        </Col>
        <Col sm='3'>
          <B1Input
            id='house_nbr'
            type='text'
            placeholder=''
            autoComplete='address-line2'
            label='Hausnummer'
            value={registrationData.data.house_nbr}
            onInput={(e) => setValue('house_nbr', e)}
            feedbackClass={houseNbrFeedback ? 'warning' : undefined}
          />
        </Col>
      </Row>
      <Row>
        <Col sm='6'>
          <B1Input
            id='zip'
            type='text'
            placeholder=''
            autoComplete='postal-code'
            label='Postleitzahl'
            value={registrationData.data.zip}
            onInput={(e) => setValue('zip', e)}
            feedback={zipFeedback ? 'Bitte gib Deine PLZ an.' : undefined}
            feedbackClass={zipFeedback ? 'warning' : undefined}
          />
        </Col>
        <Col sm='6'>
          <B1Input
            id='place'
            type='text'
            placeholder=''
            autoComplete='address-level2'
            label='Stadt'
            value={registrationData.data.place}
            onInput={(e) => setValue('place', e)}
            feedback={placeFeedback ? 'Bitte gib Deine Stadt an.' : undefined}
            feedbackClass={placeFeedback ? 'warning' : undefined}
          />
        </Col>
      </Row>
      <div className={`b1-form-element-combined country${countryFeedback ? ' warning' : ''}`}>
        <label htmlFor='country'>Land</label>
        <div className='combined-form'>
          {registrationData.data.country_code && (
            <Twemoji emoji={getEmojiFlag(registrationData.data.country_code as TCountryCode)} />
          )}
          <select
            id='country'
            autoComplete='country-name'
            value={registrationData.data.country_code}
            onInput={(e) => setValue('country_code', e)}
          >
            <option value=''>Wähle Dein Land</option>
            {getCountryOptions().map((country, index) => (
              <option key={index} value={country.value}>
                {country.label}
              </option>
            ))}
          </select>
        </div>
        {countryFeedback && <span className='form-feedback'>Bitte wähle Dein Land aus.</span>}
      </div>
      <PhoneNumber
        prefixOptions={getPhoneNumberPrefixes()}
        phoneNumber={registrationData.data.phone_number}
        onPhoneNumberChange={(phoneNumber) => setValue('phone_number', phoneNumber)}
        phoneNumberFeedback={phoneNumberFeedback}
        onPhoneNumberFeedbackChange={(feedback) => setPhoneNumberFeedback(feedback)}
      />

      {!hideDeliveryInfo && (
        <div className='delivery-info'>
          <img src='assets/icons/dhl-logo.svg' />
          <span>Info: Die Lieferzeit beträgt circa 3-4 Tage.</span>
        </div>
      )}

      <B1Button type='submit' className='button-block'>
        {submitButtonText ?? 'Weiter'}
      </B1Button>
    </form>
  );
};

export default AddressForm;
