import { useIonToast } from '@ionic/react';
import React, { FormEvent, useContext, useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Carousel from 'react-bootstrap/Carousel';
import Collapse from 'react-bootstrap/Collapse';

import { CourseType } from '../../../api/interfaces/course';
import SupportElementGroup from '../../../api/interfaces/support-element';
import { getSupportElements, sendSupportMessage } from '../../../api/support';
import { AuthContext } from '../../../providers/AuthProvider';
import { isApiError } from '../../../utils/api-util';
import B1Button from '../../buttons/B1Button';
import CustomLink from '../../custom-link/CustomLink';
import B1Textarea from '../../forms/B1Textarea';
import B1Modal from '../B1Modal';

import './SupportModal.scss';

interface DirectContactOptionsProps {
  open: boolean;
  onOpen: () => void;
  message: string;
  onMessageChange: (text: string) => void;
  onSend: (event: FormEvent<HTMLFormElement>) => void;
}

const DirectContactOptions: React.FC<DirectContactOptionsProps> = ({
  open,
  onOpen,
  message,
  onMessageChange,
  onSend,
}) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      {isAuthenticated && (
        <>
          <button type='button' className='direct-contact-button mt-4' onClick={onOpen}>
            <span>Oder kontaktiere uns direkt</span> <img src='assets/icons/dropdown.svg' />
          </button>
          <Collapse in={open}>
            <form className='b1-form' onSubmit={onSend}>
              <B1Textarea
                placeholder='Maximal 1000 Zeichen'
                rows={3}
                value={message}
                onChange={(e) => onMessageChange(e.currentTarget.value)}
              />
              <B1Button type='submit' className='button-reverse button-block mt-3'>
                Absenden
              </B1Button>
            </form>
          </Collapse>
        </>
      )}
      <div className='third-party-login-separator'>oder</div>
      <div className='other-contact-options'>
        <p className='mb-3'>
          E-Mail: <a href='mailto:info@bootsschule1.de'>info@bootsschule1.de</a>
        </p>
        <p className='mb-3'>
          Tel.: <a href='tel:+4958197093000'>0581 97093000</a>
        </p>
        <p>
          1:1 Session: <CustomLink href='https://calendly.com/bootsschulee1/15'>Link</CustomLink>
        </p>
      </div>
    </>
  );
};

interface SupportModalProps {
  currentCourseType?: CourseType | string;
  open: boolean;
  onModalClose: () => void;
}

const SupportModal: React.FC<SupportModalProps> = ({ currentCourseType, open, onModalClose }) => {
  const [presentToast] = useIonToast();

  const [supportElementGroups, setSupportElementGroups] = useState<SupportElementGroup[]>([]);
  const [selectedSupportElement, setSelectedSupportElement] = useState<number>();
  const [supportCarouselIndex, setSupportCarouselIndex] = useState<number>(0);
  const [supportFieldOpen, setSupportFieldOpen] = useState(false);
  const [supportMessage, setSupportMessage] = useState('');

  useEffect(() => {
    if (open) {
      getSupportElements(currentCourseType).then((res) => {
        if (isApiError(res)) {
          console.error(res);

          // Lade Standard Support Elemente
          getSupportElements().then((res) => {
            if (isApiError(res)) {
              console.error(res);
            } else {
              setSupportElementGroups(res);
            }
          });
        } else {
          setSupportElementGroups(res);
        }
      });
    }
  }, [currentCourseType, open]);

  const handleSendSupportMessage = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!supportMessage) return;

    const res = await sendSupportMessage(supportMessage);

    if (isApiError(res)) {
      console.error(res);
    } else {
      setSupportMessage('');
      setSupportFieldOpen(false);

      presentToast({
        message: 'Deine Nachricht wurde erfolgreich versendet. Wir melden uns bei Dir!',
        duration: 3000,
        position: 'bottom',
        color: 'success',
      });
    }
  };

  return (
    <B1Modal
      className='support-modal'
      open={open}
      onModalClose={() => onModalClose()}
      topContent={
        supportCarouselIndex === 1 && (
          <B1Button
            className='button-transparent back-button'
            iconOnly
            click={() => setSupportCarouselIndex(0)}
          >
            <img src='assets/icons/short-left-arrow.svg' className='d-block d-sm-none' />
            <img src='assets/icons/long-left-arrow.svg' className='d-none d-sm-block' />
          </B1Button>
        )
      }
    >
      <Carousel
        interval={null}
        indicators={false}
        controls={false}
        activeIndex={supportCarouselIndex}
      >
        <Carousel.Item>
          <h1 className='mb-3 regular'>Supportthemen</h1>
          <h5 className='mb-4'>Bitte wähle das Thema, das Dich interessiert:</h5>
          <div className='support-element-group-buttons'>
            {supportElementGroups.map((group, index) => (
              <B1Button
                key={index}
                className='button-transparent'
                click={() => {
                  setSelectedSupportElement(index);
                  setSupportCarouselIndex(1);
                }}
              >
                {group.title}
              </B1Button>
            ))}
          </div>
          <DirectContactOptions
            open={supportFieldOpen}
            onOpen={() => setSupportFieldOpen(!supportFieldOpen)}
            message={supportMessage}
            onMessageChange={(text) => setSupportMessage(text)}
            onSend={handleSendSupportMessage}
          />
        </Carousel.Item>
        <Carousel.Item>
          <h1 className='mb-3 regular'>Was ist Dein Anliegen?</h1>
          <Accordion>
            {selectedSupportElement !== undefined &&
              supportElementGroups[selectedSupportElement] &&
              supportElementGroups[selectedSupportElement].support_elements.map(
                (element, index) => (
                  <Accordion.Item key={index} eventKey={String(index)}>
                    <Accordion.Header>{element.question}</Accordion.Header>
                    <Accordion.Body>
                      <p>{element.answer}</p>
                    </Accordion.Body>
                  </Accordion.Item>
                )
              )}
          </Accordion>
          <DirectContactOptions
            open={supportFieldOpen}
            onOpen={() => setSupportFieldOpen(!supportFieldOpen)}
            message={supportMessage}
            onMessageChange={(text) => setSupportMessage(text)}
            onSend={handleSendSupportMessage}
          />
        </Carousel.Item>
      </Carousel>
    </B1Modal>
  );
};

export default SupportModal;
