import React, { useEffect, useState } from 'react';

import { Share } from '@capacitor/share';
import { IonIcon } from '@ionic/react';
import { shareOutline } from 'ionicons/icons';

import { AccountInfo } from '../../../api/interfaces/account-info';
import B1Button from '../../buttons/B1Button';
import B1Input from '../../forms/B1Input';
import B1Modal from '../B1Modal';

import './ReferralModal.scss';

interface ReferralModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  accountInfo: AccountInfo | undefined;
}

const ReferralModal: React.FC<ReferralModalProps> = ({ open, setOpen, accountInfo }) => {
  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    Share.canShare().then((res) => {
      setCanShare(res.value);
    });
  }, []);

  const coupon = accountInfo?.user.kwk_coupon?.toUpperCase();

  const getReferralLink = () => {
    if (!coupon) return '';

    return encodeURI(
      `https://bootsschule1.de/?coupon_code=${coupon}&utm_source=fwf&utm_content=${accountInfo?.user.email}`
    );
  };

  return (
    <B1Modal className='referral-modal' open={open} onModalClose={() => setOpen(false)}>
      <h1 className='emoji'>🤝</h1>
      <h2>Wirb Deine Freunde</h2>
      <p className='mb-4'>Hol Deine Freunde an Bord und schenke einen exklusiven Rabatt! 🚀</p>
      {/* <p className='mb-4'>Für jede Empfehlung erhältst Du eine Überraschung. 🎁</p> */}
      <B1Input readonly id='referral-code' value={coupon} />
      {canShare && (
        <B1Button
          className='button-block'
          click={() => {
            Share.share({
              title: 'Dein Bootsschule1 Rabattcode 🚀',
              text: `🎁 Ich habe für Dich einen exklusiven Rabattcode für Bootsschule1®: ${coupon}\n\n🤓 Hier geht's zur Kursübersicht:`,
              url: getReferralLink(),
              dialogTitle: 'Mit Freunden teilen',
            }).then(() => setOpen(false));
          }}
        >
          <IonIcon icon={shareOutline}></IonIcon>
          Link teilen
        </B1Button>
      )}
    </B1Modal>
  );
};

export default ReferralModal;
