import {
  RouteInfo,
  getConfig,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from '@ionic/react';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { getUserCourses } from '../api/courses/courses';
import { CourseType } from '../api/interfaces/course';
import UserCourse from '../api/interfaces/user-course';
import AllInOneCourseCard from '../components/course/AllInOneCourseCard';
import CourseShowcaseCard from '../components/course/CourseShowcaseCard';
import Stories from '../components/stories/Stories';
import { isApiError } from '../utils/api-util';
import {
  BSPD_COURSE_TYPES,
  BSP_COURSE_TYPES,
  FKN_COURSE_TYPES,
  PSBF_COURSE_TYPES,
  PSRC_COURSE_TYPES,
  PUBI_COURSE_TYPES,
  SBFBS_COURSE_TYPES,
  SBF_COURSE_TYPES,
  SHOWCASE_COURSE_TYPES,
  SKN_COURSE_TYPES,
  SKS_COURSE_TYPES,
  SRC_COURSE_TYPES,
  STANDALONE_COURSE_TYPES,
  UBI_COURSE_TYPES,
  getCourseName,
  getCourseOrderIndex,
  getCourseUpgradeShopLink,
  getCoursesProgress,
  getMainCourse,
} from '../utils/course-util';
import Layout from './Layout';

import './Home.scss';

interface ClaimedCourseCardProps {
  userCourses: UserCourse[];
  courseTypes: CourseType[];
  courseName?: string;
  mainCourse?: CourseType;
}

const ClaimedCourseCard: React.FC<ClaimedCourseCardProps> = ({
  userCourses,
  courseTypes,
  courseName,
  mainCourse,
}) => {
  const courses = userCourses.filter((c) => courseTypes.includes(c.identifier));

  if (courses.some((c) => c.claimed)) {
    if (mainCourse && !courses.find((c) => c.identifier === mainCourse && c.claimed)) {
      return <></>;
    }

    const inactive = courses.every((c) => !c.active);

    const getValidUntil = () => {
      const filteredCourses = courses
        .filter((c) => c.claimed && c.active && !c.identifier.toUpperCase().startsWith('P'))
        .sort((a, b) => {
          if (a.valid_until && b.valid_until) {
            return DateTime.fromISO(a.valid_until).diff(DateTime.fromISO(b.valid_until))
              .milliseconds;
          } else {
            return 0;
          }
        });

      for (const course of filteredCourses) {
        if (course.valid_until) {
          const date = DateTime.fromISO(course.valid_until);
          const daysValid = Math.round(date.diffNow('days').days);

          if (daysValid === 0) return '⚠️ Dein Kurszugang läuft heute ab.';
          if (daysValid <= 90)
            return `⚠️ Dein Kurszugang läuft in ${daysValid} Tag${daysValid === 1 ? '' : 'en'} ab.`;
        }
      }

      return '';
    };

    const claimedCourseTypes = courses
      .filter((c) => c.claimed)
      .map((c) => c.identifier)
      .sort((a, b) => getCourseOrderIndex(a) - getCourseOrderIndex(b));

    return (
      <Col xs={12} md={6}>
        <AllInOneCourseCard
          title={courseName ?? getCourseName(courseTypes[0])}
          progress={getCoursesProgress(courses, courseTypes)}
          link={`/course/${getMainCourse(courseTypes[0])}`}
          hasChevronIcon
          bottomDescription={inactive ? '❌ Dein Kurszugang ist abgelaufen.' : getValidUntil()}
          locked={inactive}
          icon
          inlineIcons
          lockedLink={{
            url: getCourseUpgradeShopLink(claimedCourseTypes),
            type: 'upgrade',
            courseIdentifier: claimedCourseTypes[0],
          }}
        />
      </Col>
    );
  } else {
    return <></>;
  }
};

const Home: React.FC = () => {
  const ionRouter = useIonRouter();
  const [courses, setCourses] = useState<UserCourse[]>([]);
  const [error, setError] = useState('');

  const locationState = ionRouter.routeInfo as RouteInfo<{ from?: string }>;

  const claimedCourses = courses.filter((c) => c.active && c.claimed);
  const standaloneCourses = claimedCourses.filter((c) =>
    STANDALONE_COURSE_TYPES.includes(c.identifier)
  );
  const unclaimedCourses = courses.filter(
    (c) => !c.claimed && SHOWCASE_COURSE_TYPES.includes(c.identifier)
  );

  unclaimedCourses.sort(
    (a, b) => getCourseOrderIndex(a.identifier) - getCourseOrderIndex(b.identifier)
  );

  useIonViewDidEnter(() => {
    getConfig()?.set('swipeBackEnabled', false);
  }, []);

  useIonViewWillLeave(() => {
    getConfig()?.set('swipeBackEnabled', true);
  }, []);

  useIonViewWillEnter(() => {
    getUserCourses().then((res) => {
      if (isApiError(res)) {
        setError('Fehler beim Laden der Kurse');
      } else {
        setCourses(res);
      }
    });
  }, []);

  const getLocationFromText = () => {
    switch (locationState.routeOptions?.from) {
      case 'redeem-code':
        return (
          <div className='b1-alert mb-4 justify-content-start'>
            <img src='assets/icons/check-icon-filled-2.svg' />
            <p>Du hast den Code erfolgreich eingelöst. Viel Spaß beim Lernen!</p>
          </div>
        );
      case 'reset-password':
        return (
          <div className='b1-alert mb-4 justify-content-start'>
            <img src='assets/icons/check-icon-filled-2.svg' />
            <p>Du hast Dein Passwort erfolgreich zurückgesetzt und bist jetzt eingeloggt.</p>
          </div>
        );
      default:
        return <></>;
    }
  };

  const hasCourse = (courseType: CourseType) => {
    return !!courses.find((c) => c.identifier === courseType && c.claimed);
  };

  return (
    <Layout contentClassName='dashboard'>
      {getLocationFromText()}
      {!!error && error}
      {!error && (
        <div className='courses-overview-dashboard'>
          <Stories />
          <h1 className='no-hyphens'>Deine Kurse</h1>
          <Row className='g-3'>
            {!hasCourse(CourseType.SBFB) &&
              !hasCourse(CourseType.SBFS) &&
              !hasCourse(CourseType.SBFBS) &&
              !hasCourse(CourseType.BSP) &&
              !hasCourse(CourseType.BSPD) &&
              !hasCourse(CourseType.SRC) &&
              !hasCourse(CourseType.UBI) &&
              !hasCourse(CourseType.SKS) &&
              !hasCourse(CourseType.FKN) &&
              !hasCourse(CourseType.SKN) &&
              !hasCourse(CourseType.PSBFS) &&
              !hasCourse(CourseType.PSBFB) &&
              !hasCourse(CourseType.PSRC) &&
              !hasCourse(CourseType.PUBI) &&
              standaloneCourses.length === 0 && <p>Du hast noch keine Kurse gebucht.</p>}
            <ClaimedCourseCard
              userCourses={courses}
              courseTypes={SBF_COURSE_TYPES}
              courseName='Sportbootführerschein (SBF)'
            />
            <ClaimedCourseCard
              userCourses={courses}
              courseTypes={SBFBS_COURSE_TYPES}
              mainCourse={CourseType.SBFBS}
            />
            <ClaimedCourseCard userCourses={courses} courseTypes={BSP_COURSE_TYPES} />
            <ClaimedCourseCard
              userCourses={courses}
              courseTypes={BSPD_COURSE_TYPES}
              mainCourse={CourseType.BSPD}
            />
            <ClaimedCourseCard userCourses={courses} courseTypes={SRC_COURSE_TYPES} />
            <ClaimedCourseCard userCourses={courses} courseTypes={UBI_COURSE_TYPES} />
            <ClaimedCourseCard userCourses={courses} courseTypes={SKS_COURSE_TYPES} />
            <ClaimedCourseCard userCourses={courses} courseTypes={FKN_COURSE_TYPES} />
            <ClaimedCourseCard userCourses={courses} courseTypes={SKN_COURSE_TYPES} />
            {standaloneCourses.map((c, index) => (
              <Col key={index} xs={12} md={6}>
                <AllInOneCourseCard userCourse={c} hasChevronIcon hideDescription />
              </Col>
            ))}

            {/* Partner courses */}
            <ClaimedCourseCard
              userCourses={courses}
              courseTypes={PSBF_COURSE_TYPES}
              courseName='Sportbootführerschein (SBF)'
            />
            <ClaimedCourseCard userCourses={courses} courseTypes={PSRC_COURSE_TYPES} />
            <ClaimedCourseCard userCourses={courses} courseTypes={PUBI_COURSE_TYPES} />
          </Row>
          {unclaimedCourses.length > 0 && (
            <>
              <hr />
              <h1 className='no-hyphens'>Weiterführende Scheine</h1>
              <Row className='g-3'>
                {unclaimedCourses.map((c, index) => (
                  <CourseShowcaseCard key={index} course={c} />
                ))}
              </Row>
            </>
          )}
        </div>
      )}
    </Layout>
  );
};

export default Home;
