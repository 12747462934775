import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { CourseType } from '../../../api/interfaces/course';
import UserCourse from '../../../api/interfaces/user-course';
import AllInOneCourseCard from '../../../components/course/AllInOneCourseCard';
import DashboardWrapper from '../dashboard-wrapper/DashboardWrapper';

const SBFDashboard: React.FC = () => {
  const [userCourses, setUserCourses] = useState<UserCourse[]>([]);
  const [hasNavigationCourse, setHasNavigationCourse] = useState<boolean>();
  const [hasInlandBaseQuestionsCourse, setHasInlandBaseQuestionsCourse] = useState<boolean>();
  const [allCoursesExpired, setAllCoursesExpired] = useState(false);

  const getCourseType = () => {
    if (userCourses.find((c) => c.identifier === CourseType.SBFN && c.active && c.claimed)) {
      return CourseType.SBFS;
    } else if (userCourses.find((c) => c.identifier === CourseType.SBFB && c.active && c.claimed)) {
      return CourseType.SBFB;
    } else {
      return CourseType.SBFS;
    }
  };

  useEffect(() => {
    setAllCoursesExpired(
      !userCourses.some(
        (c) =>
          (c.identifier === CourseType.SBFN || c.identifier === CourseType.SBFB) &&
          c.active &&
          c.claimed &&
          !c.expired
      )
    );
  }, [userCourses]);

  return (
    <DashboardWrapper
      courseType={getCourseType()}
      onUserCoursesLoaded={(userCourses) => {
        setUserCourses(userCourses);
        setHasNavigationCourse(
          !!userCourses.find((c) => c.identifier === CourseType.SBFN && c.active && c.claimed)
        );
        setHasInlandBaseQuestionsCourse(
          !!userCourses.find((c) => c.identifier === CourseType.SBFBB && c.active && c.claimed)
        );
      }}
    >
      <Row className='g-3 course-cards'>
        <Col xl='8'>
          <Row className='g-3'>
            <Col xs='6'>
              <AllInOneCourseCard
                userCourse={userCourses.find((c) => c.identifier === CourseType.SBFS)}
                hasPromotionImage
                hasChevronIcon
              />
            </Col>
            <Col xs='6'>
              <AllInOneCourseCard
                userCourse={userCourses.find((c) => c.identifier === CourseType.SBFB)}
                hasPromotionImage
                hasChevronIcon
              />
            </Col>
            {hasInlandBaseQuestionsCourse ? (
              <Col md='4'>
                <AllInOneCourseCard
                  userCourse={userCourses.find((c) => c.identifier === CourseType.SBFBB)}
                  icon='assets/icons/boat.svg'
                  wideIcon
                  hasChevronIcon
                  isCompact
                  // description='Schließe erst Lektion 2 ab bevor Du den Lernmodus verwenden kannst.'
                />
              </Col>
            ) : (
              <Col md='4'>
                <AllInOneCourseCard
                  userCourse={userCourses.find((c) => c.identifier === CourseType.SBFN)}
                  icon='assets/icons/BS1_Icon_Navigation.svg'
                  hasChevronIcon
                  isCompact
                  // description='Schließe erst Lektion 2 ab bevor Du den Lernmodus verwenden kannst.'
                />
              </Col>
            )}
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/knots.svg'
                wideIcon
                title='Knoten'
                description='Übe hier die Knoten.'
                locked={allCoursesExpired}
                hasChevronIcon
                isCompact
                link={`/course/${getCourseType()?.toLowerCase()}/knots`}
              />
            </Col>
            <Col md='4'>
              <AllInOneCourseCard
                icon='assets/icons/boat.svg'
                wideIcon
                title='Praxis & Prüfung'
                // description='Schließe erst Lektion 2 ab bevor Du das Praxistraining starten kannst.'
                description='Bereite Dich hier auf die Praxisprüfung vor und buche Termine zur Fahrausbildung und Prüfung.'
                locked={allCoursesExpired}
                link={`/course/${getCourseType()?.toLowerCase()}/practical-training`}
                hasChevronIcon
                isCompact
              />
            </Col>
          </Row>
        </Col>
        <Col xl='4'>
          <Row className='g-3'>
            <Col sm='12'>
              <AllInOneCourseCard
                // locked
                link='/course/sbf/smartmode'
                icon='assets/icons/learn-mode.svg'
                title='Smartmodus'
                description='Hier kannst Du alle Fragen, die in der Prüfung vorkommen können, effizient lernen.'
                locked={allCoursesExpired}
              />
            </Col>
            <Col sm='12'>
              <AllInOneCourseCard
                // locked
                link='/course/sbf/exam-training'
                icon='assets/icons/exam-simulation.svg'
                title='Prüfungssimulation'
                description='Hier kannst Du mit den offiziellen Prüfungsbögen in einem echten Prüfungsszenario lernen.'
                locked={allCoursesExpired}
              />
            </Col>
            {hasNavigationCourse && (
              <Col sm='12'>
                <AllInOneCourseCard
                  // locked
                  link={`/course/${CourseType.SBFN.toLowerCase()}/navigation-training`}
                  icon='assets/icons/BS1_Icon_Navigation_Exam.svg'
                  title='Navigation Prüfungssimulation'
                  description='Hier kannst Du die offiziellen Prüfungsaufgaben des Navigationskurses lernen.'
                  locked={allCoursesExpired}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </DashboardWrapper>
  );
};

export default SBFDashboard;
