import { Question } from './quiz';

export interface SmartmodeLesson {
  id: number;
  title: string;
  questions_count: number;
  level_1_count: number;
  level_2_count: number;
  level_3_count: number;
}

export interface SmartmodeInterface {
  questions_pdf?: string;
  lessons: SmartmodeLesson[];
}

export interface SmartmodeQuestion extends Question {
  lesson_title: string;
  level: number;
  chapter_id: number;
}

export const isSmartmodeQuestion = (question: Question): question is SmartmodeQuestion => {
  const q = question as SmartmodeQuestion;
  return q.lesson_title !== undefined && q.level !== undefined && q.chapter_id !== undefined;
};
