import { useIonRouter } from '@ionic/react';
import React from 'react';

import PasswordForm from '../../../components/password-form/PasswordForm';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import Layout from '../../Layout';

import '../../LoginRegister.scss';
import './RegisterPassword.scss';

const RegisterPassword: React.FC = () => {
  const [registrationData, dispatchRegistrationData] = useRegistrationDataState();
  const ionRouter = useIonRouter();

  const submitPassword = (password: string) => {
    dispatchRegistrationData({ type: 'SET_DATA_KEY', key: 'password', value: password });

    ionRouter.push('/register/personal-data');

    return true;
  };

  return (
    <Layout backButton='/login/email' dontRefreshUserStateWhenLoggedIn>
      <div className='register-page register-page-password'>
        <h2 className='mb-3'>Vervollständige Dein Profil</h2>
        <h4 className='mb-4'>
          Schütze Dich und Deine persönlichen Daten. Bitte vergib noch ein sicheres Passwort für
          Deinen Account.
        </h4>
        <PasswordForm email={registrationData.data.email} onSubmit={submitPassword} />
      </div>
    </Layout>
  );
};

export default RegisterPassword;
