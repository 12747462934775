import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { PushMessage } from '../interfaces/push-message';

export const getPushMessages = async () => {
  const res = await makeApiRequest<PushMessage[]>('GET', 'v1/account/push-messages');

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const markPushMessageAsRead = async (pushMessageId: number) => {
  const res = await makeApiRequest('POST', 'v1/account/push-messages', undefined, {
    push_message_id: pushMessageId,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};
