import { useIonRouter, useIonToast } from '@ionic/react';
import React, { useEffect, useReducer, useRef } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { useParams } from 'react-router';

import { checkQuestionAnswer } from '../../api/courses/answer';
import { getLessonQuestions, updateQuestionProgress } from '../../api/courses/smart-mode';
import { QuestionType } from '../../api/interfaces/quiz';
import { SmartmodeQuestion } from '../../api/interfaces/smartmode';
import B1Button from '../../components/buttons/B1Button';
import Question from '../../components/questions/Question';
import { QUIZ_REDUCER, SmartmodeQuizQuestion } from '../../reducers/quiz-reducer';
import { isApiError } from '../../utils/api-util';
import { getMainCourse } from '../../utils/course-util';
import { isAnswerCorrect } from '../../utils/quiz-util';
import Layout from '../Layout';

import './SmartmodeQuiz.scss';

const SmartmodeQuiz: React.FC = () => {
  const ionRouter = useIonRouter();
  const [presentToast] = useIonToast();

  const { identifier, lessonId } = useParams<{ identifier: string; lessonId: string }>();
  const pageRef = useRef<HTMLElement>(null);
  const [quiz, dispatchQuiz] = useReducer(QUIZ_REDUCER<SmartmodeQuestion, SmartmodeQuizQuestion>, {
    loaded: false,
    questions: [],
  });

  useEffect(() => {
    getLessonQuestions(identifier, Number(lessonId)).then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      const questions = res.filter((question) => question.level < 3);

      questions.sort((a, b) => {
        if (a.level === b.level) {
          return a.id - b.id;
        } else {
          return a.level - b.level;
        }
      });

      dispatchQuiz({
        type: 'SET_QUESTIONS',
        questions: questions,
      });
    });
  }, [identifier, lessonId]);

  const hasNextQuestion = () => {
    return quiz.questions.length > 1 || quiz.questions[0].level < 3;
  };

  const goToNextQuestion = () => {
    dispatchQuiz({
      type: 'MOVE_QUESTION',
      questionIndex: 0,
      correct: isAnswerCorrect(quiz.questions[0]),
    });
  };

  const evaluateAnswer = async () => {
    const question = quiz.questions[0];
    let correct = false;

    if (question.type === QuestionType.TF) {
      if (!question.textAnswerInput) return;

      dispatchQuiz({
        type: 'SET_ANSWER_EVALUATION_STATUS',
        questionIndex: 0,
        status: 'evaluating',
      });

      const res = await checkQuestionAnswer(identifier, question.id, question.textAnswerInput);

      if (isApiError(res)) {
        console.error(res);

        dispatchQuiz({
          type: 'SET_ANSWER_EVALUATION_STATUS',
          questionIndex: 0,
          status: 'not-evaluated',
        });
      } else {
        dispatchQuiz({
          type: 'SET_TEXT_ANSWER_RESPONSE',
          questionIndex: 0,
          userPoints: res.user_points,
          totalPoints: res.total_points,
          responseText: res.answer,
          responsePictureLink: res.picture_link,
        });

        correct = res.user_points === res.total_points;
      }
    } else {
      if (question.checkedAnswers !== undefined) {
        dispatchQuiz({
          type: 'SET_ANSWER_EVALUATION_STATUS',
          questionIndex: 0,
          status: 'evaluated',
        });

        correct = isAnswerCorrect(question);
      }
    }

    dispatchQuiz({
      type: 'UPDATE_QUESTION',
      questionIndex: 0,
      correct: correct,
    });

    updateQuestionProgress(identifier, question.id, correct).then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }
    });
  };

  const showQuestionHint =
    quiz.questions[0] &&
    quiz.questions[0].evaluationStatus === 'evaluated' &&
    !!quiz.questions[0].hint;

  const renderTooltip = (props: OverlayInjectedProps) => {
    return (
      <Tooltip className='b1-tooltip' {...props}>
        Eine Prüfungsfrage wird als gelernt markiert, sobald diese 3-mal in Folge richtig
        beantwortet wurde.
      </Tooltip>
    );
  };

  return (
    <Layout
      pageRef={pageRef}
      backButton={`/course/${getMainCourse(identifier)}/smartmode`}
      contentClassName='smartmode-quiz'
      currentCourseType={identifier}
    >
      {quiz.loaded &&
        (quiz.questions[0] ? (
          <>
            <OverlayTrigger
              placement='bottom'
              overlay={(props) => renderTooltip(props)}
              trigger={['hover', 'focus']}
            >
              <div className='smartmode-question-level' tabIndex={0}>
                🌟 {quiz.questions[0].level}/3
              </div>
            </OverlayTrigger>
            <div className='smartmode-quiz-container'>
              <h1>Smartmodus: Lektion {quiz.questions[0].lesson_title}</h1>

              <Question
                courseIdentifier={identifier}
                question={quiz.questions[0]}
                showQuestionHint={showQuestionHint}
                hasNextQuestion={hasNextQuestion()}
                onAnswerSelected={(answerId) => {
                  dispatchQuiz({
                    type: 'SET_CHECKED_ANSWER',
                    questionIndex: 0,
                    checkedAnswer: answerId,
                  });
                }}
                onAnswerTextChanged={(text) => {
                  dispatchQuiz({
                    type: 'SET_TEXT_ANSWER',
                    questionIndex: 0,
                    textAnswer: text,
                  });
                }}
                onGoToNextQuestionClick={goToNextQuestion}
                onGoToSummaryClick={() => {
                  presentToast({
                    message:
                      'Du hast alle Fragen 3-mal richtig beantwortet.\n' +
                      'Herzlichen Glückwunsch!',
                    duration: 3000,
                    position: 'bottom',
                    color: 'success',
                  });
                  ionRouter.push(`/course/${getMainCourse(identifier)}/smartmode`, 'back');
                }}
                onEvaluateAnswerClick={evaluateAnswer}
                pageRef={pageRef}
              />
            </div>
          </>
        ) : (
          <div className='smartmode-quiz-container'>
            <h1>Smartmodus</h1>
            <p className='mb-3'>
              Du hast bereits alle Fragen dieser Lektion 3-mal richtig beantwortet.
            </p>
            <B1Button href={`/course/${getMainCourse(identifier)}/smartmode`}>
              Zurück zur Übersicht
            </B1Button>
          </div>
        ))}
    </Layout>
  );
};

export default SmartmodeQuiz;
