import { useIonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { TfiDownload } from 'react-icons/tfi';

import { getTrips, submitTripRequest } from '../../../../api/courses/sks';
import { CourseType } from '../../../../api/interfaces/course';
import { Trips as ITrips, Trip } from '../../../../api/interfaces/trip';
import B1Button from '../../../../components/buttons/B1Button';
import B1Modal from '../../../../components/modal/B1Modal';
import ChangeAddressModal from '../../../../components/modal/change-address-modal/ChangeAddressModal';
import ChangePersonalDataModal from '../../../../components/modal/change-personal-data-modal/ChangePersonalDataModal';
import { isApiError } from '../../../../utils/api-util';
import Layout from '../../../Layout';
import TripRegion from './TripRegion';

import './Trips.scss';

const Trips: React.FC = () => {
  const [presentToast] = useIonToast();

  const [trips, setTrips] = useState<ITrips>();
  const [tripCounter, setTripCounter] = useState(0);
  const [tripModalOpen, setTripModalOpen] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState<Trip>();
  const [changeAddressModalOpen, setChangeAddressModalOpen] = useState<boolean>(false);
  const [changePersonalDataModalOpen, setChangePersonalDataModalOpen] = useState<boolean>(false);

  useEffect(() => {
    getTrips().then((res) => {
      if (isApiError(res)) {
        console.error(res);
        return;
      }

      setTrips(res);
    });
  }, [tripCounter]);

  const handleTripSelected = (trip: Trip) => {
    setSelectedTrip(trip);
    setTripModalOpen(true);
  };

  const addressChanged = () => {
    setChangeAddressModalOpen(false);
    setChangePersonalDataModalOpen(true);
  };

  const personalDataChanged = async () => {
    setChangePersonalDataModalOpen(false);

    if (!selectedTrip) return;

    const res = await submitTripRequest(selectedTrip.id);

    if (isApiError(res)) {
      if (res.statusCode === 403) {
        if (res.statusMessage?.includes('fully booked')) {
          presentToast({
            message: 'Dieser Törn ist bereits ausgebucht.',
            duration: 3000,
            position: 'bottom',
            color: 'danger',
          });
        } else {
          presentToast({
            message: 'Du hast bereits eine Törnanfrage gestellt.',
            duration: 3000,
            position: 'bottom',
            color: 'danger',
          });
        }
      } else if (res.statusCode === 404) {
        presentToast({
          message: 'Der von Dir ausgewählte Törn konnte nicht gefunden werden.',
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        });
      } else {
        console.error(res);
        presentToast({
          message:
            'Es ist ein Fehler aufgetreten, bitte versuche es später erneut oder melde Dich bei uns.',
          duration: 3000,
          position: 'bottom',
          color: 'danger',
        });
      }
    } else {
      presentToast({
        message: 'Deine Törnanfrage wurde erfolgreich versendet. Wir melden uns bei Dir!',
        duration: 3000,
        position: 'bottom',
        color: 'success',
      });
    }

    setTripCounter(tripCounter + 1);
    setTripModalOpen(false);
  };

  return (
    <Layout backButton='/course/sks' currentCourseType={CourseType.SKS} contentClassName='trips'>
      <h1 className='mb-3'>Praxistörns</h1>
      <p className='mb-3'>
        Endlich geht es los – bald sitzt Du selbst hinter dem Steuer! Beim SKS ist es für die
        Praxisprüfung notwendig, dass man 300 Seemeilen nachweist. Dieser Nachweis erfolgt durch ein
        Logbuch, bei dem andere Skipper, die mit Ihnen gesegelt sind und auch mindestens den SKS
        haben, einen Eintrag machen.
      </p>
      <h5 className='bold mb-3'>Wichtig:</h5>
      <p className='mb-3'>
        Ein Ausbildungstörn ist erst nach bestandener Theorie- und Navigationsprüfung möglich! Wenn
        Sie bereits min. 150 Seemeilen besitzen, können Sie auf den Meilentörn verzichten und auch
        ausschließlich einen Ausbildungstörn buchen.
      </p>
      <h5 className='bold mb-3'>Mein Tipp:</h5>
      <p className='mb-3'>
        Wenn Sie noch keine Seemeilen gesammelt haben, würde ich Ihnen empfehlen, die Theorie bis
        ca. 70% Lernstand zu lernen. Danach gehen Sie auf einen Meilentörn, wo Sie die ca. 150
        Seemeilen sammeln. Nach dem Törn absolvieren Sie die Theorie- und Navigationsprüfung.
        Nachdem Sie auch diese beiden Teilprüfungen dann abgeschlossen haben, gehen Sie auf einen
        Ausbildungstörn und absolvieren an dessen Ende die Praxisprüfung.
      </p>
      {/* <B1Button className='button-reverse mb-5'>
        <img src='assets/icons/play.svg' /> Video
      </B1Button> */}
      <hr className='my-5' />

      <h5 className='bold mb-3'>Meilentörns</h5>
      <p>
        Du absolvierst einen einwöchigen Segeltörn (genannt Meilentörn), auf dem Du ausschließlich
        Seemeilen sammelst, ca. 150 Stück an der Zahl. Diesen Meilentörn kannst Du sehr gerne auch
        schon absolvieren, bevor Du die Theorieprüfung abgeschlossen hast.
      </p>
      {!trips?.mt_trips || trips.mt_trips.length === 0 ? (
        <p className='mt-3'>
          <em>Es sind keine Meilentörns verfügbar.</em>
        </p>
      ) : (
        trips?.mt_trips.map((tripRegion, index) => (
          <TripRegion
            key={index}
            tripRegion={tripRegion}
            onTripSelected={handleTripSelected}
            hasTripRequested={!!trips.mt_trips.find((tr) => tr.trips.find((t) => t.trip_request))}
          />
        ))
      )}

      <h5 className='bold mt-5 mb-3'>Ausbildungstörns</h5>
      <p>
        Nach dem abgeschlossenen Meilentörn oder wenn Dir zu den benötigten 300 noch knapp 150
        Seemeilen fehlen, kannst Du diese in einem sogenannten Ausbildungstörn sammeln. Am Ende
        dieses Ausbildungstörns ist dann die praktische Prüfung. Hinweis: Du kannst diese Törnart
        auch als reinen Meilentörn ohne Prüfung absolvieren.
      </p>
      {!trips?.pt_trips || trips.pt_trips.length === 0 ? (
        <p className='mt-3'>
          <em>Es sind keine Ausbildungstörns verfügbar.</em>
        </p>
      ) : (
        trips?.pt_trips.map((tripRegion, index) => (
          <TripRegion
            key={index}
            tripRegion={tripRegion}
            onTripSelected={handleTripSelected}
            hasTripRequested={!!trips.pt_trips.find((tr) => tr.trips.find((t) => t.trip_request))}
          />
        ))
      )}

      <h5 className='bold mt-5 mb-3'>Kombi-Törns</h5>
      <p>
        Du absolvierst einen 10 bis 14 tägigen Segeltörn, bei dem der Ausbildungstörn direkt an den
        Meilentörn angehängt wird. Am Ende dieses Törns findet dann die Praxisprüfung statt.
      </p>
      {!trips?.mtpt_trips || trips.mtpt_trips.length === 0 ? (
        <p className='mt-3'>
          <em>Es sind keine Kombi-Törns verfügbar.</em>
        </p>
      ) : (
        trips?.mtpt_trips.map((tripRegion, index) => (
          <TripRegion
            key={index}
            tripRegion={tripRegion}
            onTripSelected={handleTripSelected}
            hasTripRequested={!!trips.mtpt_trips.find((tr) => tr.trips.find((t) => t.trip_request))}
          />
        ))
      )}

      <B1Modal
        className='trip-booking-modal'
        onModalClose={() => setTripModalOpen(false)}
        open={tripModalOpen}
      >
        <h1 className='emoji'>⛵</h1>
        <h2 className='mb-4'>Deine Törnanfrage</h2>
        {selectedTrip?.pdf_path && (
          <>
            <p className='mb-3'>Törnbeschreibung herunterladen:</p>
            <B1Button
              className='button-transparent mb-3'
              href={selectedTrip.pdf_path}
              downloadAndOpen
            >
              <TfiDownload /> PDF
            </B1Button>
          </>
        )}
        <p className='mb-3'>
          Nach Deiner Anfrage nehmen wir zeitnah mit Dir zur persönlichen Beratung Kontakt auf.
        </p>
        <p className='mb-3'>Überprüfe im nächsten Schritt bitte Deine persönlichen Daten.</p>
        <B1Button className='button-centered mt-4' click={() => setChangeAddressModalOpen(true)}>
          Weiter
        </B1Button>
      </B1Modal>
      <ChangeAddressModal
        open={changeAddressModalOpen}
        setOpen={setChangeAddressModalOpen}
        onAddressChanged={addressChanged}
      />
      <ChangePersonalDataModal
        open={changePersonalDataModalOpen}
        setOpen={setChangePersonalDataModalOpen}
        onPersonalDataChanged={personalDataChanged}
      />
    </Layout>
  );
};

export default Trips;
