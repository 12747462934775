import { FirebaseMessaging, GetTokenOptions } from '@capacitor-firebase/messaging';
import { Capacitor } from '@capacitor/core';

export const initFirebaseMessaging = async () => {
  if (!(await FirebaseMessaging.isSupported())) return;

  if (Capacitor.isNativePlatform()) {
    FirebaseMessaging.subscribeToTopic({ topic: 'all' });

    if (Capacitor.getPlatform() === 'android') {
      FirebaseMessaging.subscribeToTopic({ topic: 'android' });
    } else if (Capacitor.getPlatform() === 'ios') {
      FirebaseMessaging.subscribeToTopic({ topic: 'ios' });
    }
  }
};

export const getMessagingToken = async () => {
  const options: GetTokenOptions = {
    vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
  };

  if (Capacitor.getPlatform() === 'web') {
    options.serviceWorkerRegistration = await navigator.serviceWorker.register(
      import.meta.env.MODE === 'production' ? '/sw.js' : '/dev-sw.js?dev-sw',
      { type: import.meta.env.MODE === 'production' ? 'classic' : 'module' }
    );
  }

  const { token } = await FirebaseMessaging.getToken(options);

  return token;
};
