import { IonContent, IonFooter, IonModal, IonSpinner, IonToolbar } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { BiChevronDown, BiSolidVideos } from 'react-icons/bi';
import B1Markdown from '../markdown/B1Markdown';

import { getCourse } from '../../api/courses/learn-mode';
import Course from '../../api/interfaces/course';
import { isExamPaperQuestion } from '../../api/interfaces/exam-paper';
import { QuestionType, QuizQuestion } from '../../api/interfaces/quiz';
import { isSmartmodeQuestion } from '../../api/interfaces/smartmode';
import useWindowSize from '../../hooks/window-size-hook';
import ChapterContent from '../../pages/learnmode/components/ChapterContent';
import { isApiError } from '../../utils/api-util';
import { isAnswerCorrect, isAnswerPartiallyCorrect } from '../../utils/quiz-util';
import B1Button from '../buttons/B1Button';
import B1Textarea from '../forms/B1Textarea';
import B1Modal from '../modal/B1Modal';

import './Question.scss';

interface QuestionProps {
  courseIdentifier: string;
  question: QuizQuestion;
  questionIndex?: number;
  showQuestionHint?: boolean;
  hasNextQuestion?: boolean;
  hideButtons?: boolean;
  hideResult?: boolean;
  onAnswerSelected?: (answerId: number) => void;
  onAnswerTextChanged?: (text: string) => void;
  onGoToNextQuestionClick?: () => void;
  onGoToSummaryClick?: () => void;
  onEvaluateAnswerClick?: () => void;
  showAnswer?: boolean;
  pageRef?: React.RefObject<HTMLElement>;
}

const Question: React.FC<QuestionProps> = ({
  courseIdentifier,
  question,
  questionIndex,
  showQuestionHint,
  hasNextQuestion,
  hideButtons,
  hideResult,
  onAnswerSelected,
  onAnswerTextChanged,
  onGoToNextQuestionClick,
  onGoToSummaryClick,
  onEvaluateAnswerClick,
  showAnswer,
  pageRef,
}) => {
  const [chapterModalOpen, setChapterModalOpen] = useState(false);
  const [presentingElement, setPresentingElement] = useState<HTMLElement>();
  const [course, setCourse] = useState<Course>();
  const { width } = useWindowSize();

  useEffect(() => {
    setPresentingElement(pageRef?.current ?? undefined);
  }, [pageRef]);

  const getTextAnswerFeedbackClass = () => {
    if (hideResult || !question.textQuestionAnswer) return;

    if (question.textQuestionAnswer.user_points === question.textQuestionAnswer.total_points) {
      return 'success';
    } else if (question.textQuestionAnswer.user_points === 0) {
      return 'danger';
    } else {
      return 'warning';
    }
  };

  const loadCourse = () => {
    if (course) return;

    getCourse(courseIdentifier).then((res) => {
      if (isApiError(res)) {
        console.error(res);
      } else {
        setCourse(res);
      }
    });
  };

  if (showAnswer) {
    return (
      <div className='question-container'>
        <div className='question-width-container'>
          <div className='question-title'>
            <FormCheck
              type='radio'
              checked
              readOnly
              id={`question-${questionIndex}-answer`}
              className={isAnswerPartiallyCorrect(question) ? 'answer-partially-correct' : ''}
              isValid={isAnswerCorrect(question)}
              isInvalid={!isAnswerCorrect(question)}
            />
            <h5 className='bold'>
              {questionIndex !== undefined ? questionIndex + 1 + '. ' : ''}
              {question.title}
            </h5>
          </div>
          {question.picture_link && <img src={question.picture_link} className='question-doc' />}
          <div className='question-options'>
            {question.type === QuestionType.TF ? (
              <>
                {question.textAnswerInput ? (
                  <div className='text-answer'>
                    <p>{question.textAnswerInput}</p>
                  </div>
                ) : (
                  <p className='mb-3'>
                    <em>Du hast keine Antwort für diese Frage abgegeben.</em>
                  </p>
                )}
                {question.textQuestionAnswer && (
                  <div className='question-hint'>
                    <div className='question-hint-text'>
                      <img src='assets/icons/idea.svg' />
                      <div>
                        <p className='mb-2'>
                          Punkte: {question.textQuestionAnswer?.user_points} /{' '}
                          {question.textQuestionAnswer?.total_points}
                        </p>
                        <B1Markdown>{question.textQuestionAnswer.answer}</B1Markdown>
                      </div>
                    </div>
                    {question.textQuestionAnswer.picture_link && (
                      <img
                        src={question.textQuestionAnswer.picture_link}
                        className='question-hint-picture'
                      />
                    )}
                  </div>
                )}
              </>
            ) : (
              (question.checkedAnswers === undefined || question.checkedAnswers.length === 0) && (
                <p className='mb-3'>
                  <em>Du hast keine Antwort für diese Frage abgegeben.</em>
                </p>
              )
            )}
            {question.choice_answers &&
              [...question.choice_answers]
                .filter((answer) => answer.correct || question.checkedAnswers?.includes(answer.id))
                .sort((a) => (a.correct ? 1 : -1))
                .map((answer, index) => (
                  <div
                    key={index}
                    className={`question-option${
                      answer.correct ? ' answer-correct' : ' answer-wrong'
                    }`}
                  >
                    <p>{answer.title}</p>
                    {answer.picture_link && <img src={answer.picture_link} />}
                  </div>
                ))}
          </div>
        </div>
      </div>
    );
  } else {
    const renderCategoryTooltip = (props: OverlayInjectedProps) => {
      if (!isExamPaperQuestion(question)) return <></>;

      return (
        <Tooltip className='b1-tooltip' {...props}>
          {question.category && (
            <p>
              <strong>{question.category.title}</strong>
            </p>
          )}
          {question.points > 1 && <p>Punkte: {question.points}</p>}
          {question.category && <p>Benötigt zum Bestehen: {question.category.correct_to_pass}</p>}
        </Tooltip>
      );
    };

    return (
      <div className='question-container'>
        <div className='question-width-container'>
          <div className='question-header'>
            {isSmartmodeQuestion(question) && !courseIdentifier.toUpperCase().startsWith('P') && (
              <B1Button className='button-sm' click={() => setChapterModalOpen(true)}>
                <BiSolidVideos />
              </B1Button>
            )}
            {isExamPaperQuestion(question) && question.category && (
              <OverlayTrigger
                placement='bottom'
                overlay={renderCategoryTooltip}
                trigger={['hover', 'focus']}
              >
                <button type='button' className='b1-button button-sm scale-effect'>
                  {question.category.identifier}
                </button>
              </OverlayTrigger>
            )}
            <h5 className='bold question-title'>
              {questionIndex !== undefined ? questionIndex + 1 + '. ' : ''}
              {question.title}
            </h5>
          </div>
          {/* <p className='question-description'>{chapterQuestions[currentQuestionIndex].add_doc}</p> */}
          {question.picture_link && <img src={question.picture_link} className='question-doc' />}
          <div className='question-options'>
            {(question.type === QuestionType.SC || question.type === QuestionType.MC) &&
              question.choice_answers &&
              question.choice_answers.map((answer, index) => (
                <FormCheck.Label
                  key={index}
                  className={`question-option${
                    question.evaluationStatus === 'evaluated' &&
                    (question.checkedAnswers?.includes(answer.id) || answer.correct)
                      ? answer.correct
                        ? ' answer-correct'
                        : ' answer-wrong'
                      : ''
                  }`}
                >
                  <FormCheck.Input
                    name={`answer-${question.id}`}
                    type={question.type === QuestionType.SC ? 'radio' : 'checkbox'}
                    checked={question.checkedAnswers?.includes(answer.id) ?? false}
                    isValid={
                      question.evaluationStatus === 'evaluated' &&
                      question.checkedAnswers?.includes(answer.id) &&
                      answer.correct
                    }
                    isInvalid={
                      question.evaluationStatus === 'evaluated' &&
                      question.checkedAnswers?.includes(answer.id) &&
                      !answer.correct
                    }
                    disabled={question.evaluationStatus === 'evaluated'}
                    onChange={() => onAnswerSelected && onAnswerSelected(answer.id)}
                  />
                  {answer.title}
                  {answer.picture_link && <img src={answer.picture_link} />}
                </FormCheck.Label>
              ))}
            {question.type === QuestionType.TF && (
              <>
                <form className='b1-form form-white'>
                  <B1Textarea
                    className='mb-0'
                    name={`answer-${question.id}`}
                    minRows={2}
                    maxRows={10}
                    placeholder='Gib hier Deine Antwort ein'
                    value={question.textAnswerInput ?? ''}
                    onChange={(e) => onAnswerTextChanged && onAnswerTextChanged(e.target.value)}
                    disabled={
                      question.evaluationStatus === 'evaluated' ||
                      question.evaluationStatus === 'evaluating'
                    }
                    feedbackClass={getTextAnswerFeedbackClass()}
                  />
                </form>
                {question.evaluationStatus === 'evaluating' && !hideResult && (
                  <div className='loading-spinner'>
                    <IonSpinner />
                    <span>Deine Antwort wird mithilfe von KI überprüft...</span>
                  </div>
                )}
                {!hideResult && question.textQuestionAnswer?.answer && (
                  <div className='question-hint'>
                    <div className='question-hint-text'>
                      <img src='assets/icons/idea.svg' />
                      <div>
                        <p className='mb-2'>
                          Punkte: {question.textQuestionAnswer?.user_points} /{' '}
                          {question.textQuestionAnswer?.total_points}
                        </p>
                        <B1Markdown>{question.textQuestionAnswer.answer}</B1Markdown>
                      </div>
                    </div>
                    {question.textQuestionAnswer.picture_link && (
                      <img
                        src={question.textQuestionAnswer.picture_link}
                        className='question-hint-picture'
                      />
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          {showQuestionHint && (
            <div className='question-hint'>
              <div className='question-hint-text'>
                <img src='assets/icons/idea.svg' />
                <B1Markdown>{question.hint}</B1Markdown>
              </div>
              {question.hint_picture_link && (
                <img src={question.hint_picture_link} className='question-hint-picture' />
              )}
            </div>
          )}
          {!hideButtons &&
            (question.evaluationStatus === 'evaluated' ? (
              hideResult ? (
                <p className='mt-3'>Das Ergebnis erfährst Du in der Auswertung.</p>
              ) : hasNextQuestion ? (
                <B1Button className='button-block' click={onGoToNextQuestionClick}>
                  Nächste Frage
                </B1Button>
              ) : (
                <B1Button className='button-block' click={onGoToSummaryClick}>
                  Zur Auswertung
                </B1Button>
              )
            ) : (
              (!question.evaluationStatus || question.evaluationStatus === 'not-evaluated') && (
                <B1Button
                  className='button-block'
                  click={onEvaluateAnswerClick}
                  disabled={
                    (question.checkedAnswers === undefined ||
                      question.checkedAnswers.length === 0) &&
                    !question.textAnswerInput
                  }
                >
                  Antwort überprüfen
                </B1Button>
              )
            ))}
        </div>
        {isSmartmodeQuestion(question) &&
          width !== undefined &&
          (width < 768 ? (
            <IonModal
              presentingElement={presentingElement}
              mode='ios'
              className='question-chapter-modal'
              isOpen={chapterModalOpen}
              onIonModalDidDismiss={() => setChapterModalOpen(false)}
              onIonModalWillPresent={loadCourse}
            >
              <IonContent>
                {course && (
                  <ChapterContent
                    course={course}
                    identifier={courseIdentifier}
                    chapterId={question.chapter_id}
                    hideNextButton
                  />
                )}
              </IonContent>
              <IonFooter>
                <IonToolbar>
                  <button
                    type='button'
                    onClick={() => setChapterModalOpen(false)}
                    className='close-modal'
                  >
                    <span>Schließen</span>
                    <BiChevronDown />
                  </button>
                </IonToolbar>
              </IonFooter>
            </IonModal>
          ) : (
            <B1Modal
              className='question-chapter-modal'
              open={chapterModalOpen}
              onModalClose={() => setChapterModalOpen(false)}
              onModalWillOpen={loadCourse}
            >
              {course && (
                <ChapterContent
                  course={course}
                  identifier={courseIdentifier}
                  chapterId={question.chapter_id}
                  hideNextButton
                />
              )}
            </B1Modal>
          ))}
      </div>
    );
  }
};

export default Question;
