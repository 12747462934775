import { DateTime } from 'luxon';
import React, { PropsWithChildren, useContext, useEffect } from 'react';
import { BiCheckDouble } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import { markPushMessageAsRead } from '../../../api/account/push-messages';
import { PushMessage } from '../../../api/interfaces/push-message';
import { AuthContext } from '../../../providers/AuthProvider';
import { SHORT_DATE_TIME_FORMAT } from '../../../utils/date-util';
import CustomLink from '../../custom-link/CustomLink';
import B1Modal from '../B1Modal';

import './PushMessagesModal.scss';

interface PushMessageCardWrapperProps extends PropsWithChildren {
  message: PushMessage;
  onClick: () => void;
}

const PushMessageCardWrapper: React.FC<PushMessageCardWrapperProps> = ({
  message,
  onClick,
  children,
}) => {
  if (message.link) {
    if (message.externalLink) {
      return (
        <div className='scale-effect-safe'>
          <CustomLink className='push-message' href={message.link} onClick={onClick}>
            {children}
          </CustomLink>
        </div>
      );
    } else {
      return (
        <div className='scale-effect-safe'>
          <Link className='push-message' to={message.link} onClick={onClick}>
            {children}
          </Link>
        </div>
      );
    }
  } else {
    return <div className='push-message'>{children}</div>;
  }
};

interface PushMessageCardProps {
  message: PushMessage;
  onClickMarkAsRead: (messageId: number, event: React.MouseEvent) => void;
  onClickMoreInfo: () => void;
}

const PushMessageCard: React.FC<PushMessageCardProps> = ({
  message,
  onClickMarkAsRead,
  onClickMoreInfo,
}) => {
  return (
    <PushMessageCardWrapper message={message} onClick={onClickMoreInfo}>
      {message.imageUrl ? (
        <img className='push-message-image' src={message.imageUrl} />
      ) : (
        <div className='placeholder-image'>
          <span>🔔</span>
        </div>
      )}
      <div className='push-message-content'>
        <p>{message.title}</p>
        <p className='description mt-2'>{message.body}</p>

        <p className='description text-muted'>
          {DateTime.fromISO(message.created_at).toLocaleString(SHORT_DATE_TIME_FORMAT)}
        </p>
        {/* {message.read && message.readAt && (
          <p className='description text-muted'>
            {DateTime.fromISO(message.readAt).toLocaleString(SHORT_DATE_TIME_FORMAT)}
          </p>
        )} */}
      </div>
      {!message.read && (
        <button
          className='mark-as-read scale-effect'
          onClick={(event) => onClickMarkAsRead(message.id, event)}
        >
          <BiCheckDouble />
        </button>
      )}
    </PushMessageCardWrapper>
  );
};

interface PushMessagesModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const PushMessagesModal: React.FC<PushMessagesModalProps> = ({ open, setOpen }) => {
  const { pushMessages, loadPushMessages } = useContext(AuthContext);

  useEffect(() => {
    if (open) {
      loadPushMessages();
    }
  }, [open]);

  const handleMarkAsRead = async (pushMessageId: number, event?: React.MouseEvent) => {
    event?.stopPropagation();
    event?.preventDefault();

    await markPushMessageAsRead(pushMessageId);

    loadPushMessages();
  };

  return (
    <B1Modal
      className='push-messages-modal'
      open={open}
      onModalClose={() => setOpen(false)}
      enableAutoHeight
    >
      <h1 className='emoji'>🔔</h1>
      <h2 className='no-hyphens'>Benachrichtigungen</h2>

      {pushMessages.filter((p) => !p.read).length > 0 && (
        <div className='push-messages mt-5'>
          {pushMessages
            .filter((p) => !p.read)
            .map((message) => (
              <PushMessageCard
                key={message.id}
                message={message}
                onClickMarkAsRead={handleMarkAsRead}
                onClickMoreInfo={() => {
                  setOpen(message.externalLink);
                  handleMarkAsRead(message.id);
                }}
              />
            ))}
        </div>
      )}

      {pushMessages.filter((p) => p.read).length > 0 && (
        <div className='push-messages mt-3'>
          {pushMessages.filter((p) => !p.read).length > 0 && <hr className='mt-5' />}
          <h5 className='mt-3 mb-3'>Gelesene Benachrichtigungen</h5>

          {pushMessages
            .filter((p) => p.read)
            .map((message) => (
              <PushMessageCard
                key={message.id}
                message={message}
                onClickMarkAsRead={handleMarkAsRead}
                onClickMoreInfo={() => setOpen(message.externalLink)}
              />
            ))}
        </div>
      )}
    </B1Modal>
  );
};

export default PushMessagesModal;
