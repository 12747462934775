import { useIonViewDidLeave } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import Code from '../../api/interfaces/code';
import RedeemCodeForm from '../../components/redeem-code-form/RedeemCodeForm';
import { useRegistrationDataState } from '../../contexts/registration-data-context';
import Layout from '../Layout';

import '../LoginRegister.scss';

const RedeemCode: React.FC = () => {
  const { code: initialCode } = useParams<{ code?: string }>();
  const [code, setCode] = useState<Code>();
  const [continueTo, setContinueTo] = useState('/');
  const [shouldRedeemCode, setShouldRedeemCode] = useState(true);
  const [, dispatchRegistrationData] = useRegistrationDataState();

  useIonViewDidLeave(() => {
    setCode(undefined);
  });

  useEffect(() => {
    return () => {
      setCode(undefined);
    };
  }, []);

  const handleCodeChanged = (code: Code) => {
    setCode(code);

    if (code.shipping_needed) {
      setShouldRedeemCode(false);
      setContinueTo('/account/address-change');
      dispatchRegistrationData({ type: 'SET_CODE', code });
    }
  };

  return (
    <Layout backButton='/account'>
      <div className='login-page'>
        <span className='emoji'>{code ? '🔓' : '🔒'}</span>
        <h2 className='mb-4'>Weitere Kurse freischalten?</h2>
        <h4 className='mb-5'>Gib bitte Deinen Aktivierungscode ein.</h4>
        <RedeemCodeForm
          continueTo={continueTo}
          initialCode={initialCode}
          shouldRedeemCode={shouldRedeemCode}
          onCodeChecked={handleCodeChanged}
        />
      </div>
    </Layout>
  );
};

export default RedeemCode;
