import { getConfig, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import React from 'react';

import B1Button from '../../../components/buttons/B1Button';
import Layout from '../../Layout';

import '../../LoginRegister.scss';
import './RegisterSuccess.scss';

const RegisterSuccess: React.FC = () => {
  useIonViewDidEnter(() => {
    getConfig()?.set('swipeBackEnabled', false);
  }, []);

  useIonViewWillLeave(() => {
    getConfig()?.set('swipeBackEnabled', true);
  }, []);

  return (
    <Layout dontRefreshUserStateWhenLoggedIn>
      <div className='login-page login-page-success'>
        <span className='emoji'>😊</span>
        <h2 className='mb-3'>Alles bereit!</h2>
        <h4 className='mb-4'>Du kannst jetzt zu Deinen Kursen fortfahren</h4>
        <B1Button className='button-block' href='/'>
          Beginnen
        </B1Button>
      </div>
    </Layout>
  );
};

export default RegisterSuccess;
