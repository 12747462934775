import { IonSpinner } from '@ionic/react';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router';

import { verifyEmail } from '../../api/account/email';
import B1Button from '../../components/buttons/B1Button';
import { AuthContext } from '../../providers/AuthProvider';
import { isApiError } from '../../utils/api-util';
import { wait } from '../../utils/misc-util';
import Layout from '../Layout';

const ConfirmEmail: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [isVerified, setIsVerified] = React.useState<boolean>();
  const [errorMessage, setErrorMessage] = React.useState('');
  const { isAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    const time = Date.now();

    verifyEmail(token).then(async (res) => {
      const duration = Date.now() - time;

      // Artificial delay, so that it looks not "too fast".
      await wait(500 - duration);

      if (isApiError(res)) {
        setIsVerified(false);

        if (res.statusCode === 401) {
          setErrorMessage('Der Bestätigungslink ist ungültig oder abgelaufen.');
        } else if (res.statusCode === 409) {
          setErrorMessage('Die E-Mail-Adresse wurde bereits bestätigt.');
        } else if (res.statusCode === 429) {
          setErrorMessage('Du hast zu viele Anfragen gesendet. Bitte versuche es später erneut.');
        } else {
          console.error(res);
          setErrorMessage('Deine E-Mail-Adresse konnte nicht bestätigt werden.');
        }
      } else {
        setIsVerified(true);
      }
    });
  }, [token]);

  return (
    <Layout contentClassName='confirm-email'>
      <div className='register-page'>
        <span className='emoji'>📧</span>
        <h2 className='mb-4'>E-Mail-Adresse bestätigen</h2>
        <div className='mb-5'>
          {isVerified === undefined && (
            <>
              <p className='mb-3'>Bestätigung läuft...</p>
              <IonSpinner />
            </>
          )}
          {isVerified === true && (
            <>
              <img src='assets/icons/check-icon-filled-2.svg' className='mb-3' />
              <p>Deine E-Mail-Adresse wurde erfolgreich bestätigt.</p>
              {!isAuthenticated && <p className='mt-3'>Du kannst Dich jetzt einloggen.</p>}
            </>
          )}
          {isVerified === false && (
            <>
              <img src='assets/icons/warning.svg' className='mb-3' />
              <p>{errorMessage}</p>
            </>
          )}
        </div>
        {isVerified !== undefined &&
          (isAuthenticated ? (
            <B1Button href='/'>Zurück zur Startseite</B1Button>
          ) : (
            <B1Button href='/login'>Zum Login</B1Button>
          ))}
      </div>
    </Layout>
  );
};

export default ConfirmEmail;
