import React, { useContext, useEffect, useState } from 'react';

import { changeContactData } from '../../../api/account/contact-data';
import { AccountStatus } from '../../../api/interfaces/account-info';
import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import { AuthContext } from '../../../providers/AuthProvider';
import { ContactData, RegistrationData } from '../../../reducers/registration-data-reducer';
import { isApiError } from '../../../utils/api-util';
import AddressForm from '../../forms/address-form/AddressForm';
import B1Modal from '../B1Modal';

import './ChangeAddressModal.scss';

interface ChangeAddressModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  submitButtonText?: string;
  onAddressChanged: () => void;
}

const ChangeAddressModal: React.FC<ChangeAddressModalProps> = ({
  open,
  setOpen,
  submitButtonText,
  onAddressChanged,
}) => {
  const [registrationData, dispatchRegistrationData] = useRegistrationDataState();
  const { isAuthenticated, getUserData } = useContext(AuthContext);
  const [userState, setUserState] = useState<AccountStatus>();

  useEffect(() => {
    getUserData().then(setUserState);
  }, [isAuthenticated, getUserData]);

  useEffect(() => {
    dispatchRegistrationData({
      type: 'SET_CONTACT_DATA',
      contactData: {
        street: userState?.street ?? '',
        house_nbr: userState?.house_nbr ?? '',
        zip: userState?.zip ?? '',
        place: userState?.place ?? '',
        country_code: userState?.country_code ?? '',
        phone_number: userState?.phone_number ?? '',
      },
    });
  }, [userState]);

  const submitAddress = async () => {
    const data = { ...registrationData.data };

    Object.keys(data).forEach((key) => {
      const x = key as keyof RegistrationData;

      const isContactField = (field: keyof RegistrationData): field is keyof ContactData => {
        return (
          field === 'street' ||
          field === 'house_nbr' ||
          field === 'zip' ||
          field === 'place' ||
          field === 'country_code' ||
          field === 'phone_number'
        );
      };

      if (!isContactField(x)) {
        delete data[x];
      }
    });

    const result = await changeContactData(data);

    if (isApiError(result)) {
      console.error(result);
      return;
    }

    onAddressChanged();
  };

  return (
    <B1Modal
      open={open}
      onModalClose={() => setOpen(false)}
      className='change-address-modal'
      enableAutoHeight
    >
      <h1 className='emoji'>📫</h1>
      <h2 className='no-hyphens'>Stimmt Deine Adresse noch?</h2>
      <p className='mb-4'>Bitte überprüfe Deine Adresse und ergänze ggf. fehlende Angaben.</p>
      <AddressForm
        hideDeliveryInfo={true}
        submitButtonText={submitButtonText}
        onSubmit={submitAddress}
      />
    </B1Modal>
  );
};

export default ChangeAddressModal;
