import React, { PropsWithChildren } from 'react';
import { BiSolidLockAlt } from 'react-icons/bi';
import { Link } from 'react-router-dom';

import UserCourse from '../../api/interfaces/user-course';
import useWindowSize from '../../hooks/window-size-hook';
import { CourseCardLink, handleCourseLinkClick } from '../../utils/course-link-util';
import {
  getCourseImage,
  getCourseSellupPageLink,
  getCourseUpgradeShopLink,
  getValidUntil,
} from '../../utils/course-util';
import CustomLink from '../custom-link/CustomLink';
import ProgressBar from '../progressbar/ProgressBar';

import './AllInOneCourseCard.scss';

interface CourseCardWrapperProps extends PropsWithChildren {
  link: CourseCardLink;
  locked?: boolean;
  lockedLink?: CourseCardLink;
  expired: boolean;
  hasPromotionImage?: boolean;
  className?: string;
}

const CourseCardWrapper: React.FC<CourseCardWrapperProps> = ({
  link,
  locked,
  lockedLink,
  expired,
  hasPromotionImage,
  className,
  children,
}) => {
  if (link && !locked) {
    return (
      <Link to={link.url} className={className}>
        {children}
      </Link>
    );
  } else if (link && locked && hasPromotionImage && !expired) {
    return (
      <CustomLink href={link.url} className={className} onClick={() => handleCourseLinkClick(link)}>
        {children}
      </CustomLink>
    );
  } else if (locked && lockedLink && !expired) {
    return (
      <CustomLink
        href={lockedLink.url}
        className={className}
        onClick={() => handleCourseLinkClick(lockedLink)}
      >
        {children}
      </CustomLink>
    );
  } else {
    return <div className={className}>{children}</div>;
  }
};

interface IconProps {
  icon?: string | boolean;
  locked?: boolean;
  wideIcon?: boolean;
}

const Icon: React.FC<IconProps> = ({ icon, locked, wideIcon }) => {
  if (typeof icon === 'string' && icon && !locked) {
    return <img className={`course-image${wideIcon ? ' wide-image' : ''}`} src={icon} />;
  } else if (icon && locked) {
    return <BiSolidLockAlt className='lock-icon' />;
  }
  return <></>;
};

interface ChevronIconProps {
  link: CourseCardLink;
  locked?: boolean;
  lockedLink?: CourseCardLink;
  expired: boolean;
  hasPromotionImage?: boolean;
}

const ChevronIcon: React.FC<ChevronIconProps> = ({
  link,
  locked,
  lockedLink,
  expired,
  hasPromotionImage,
}) => {
  if (!locked || (link && hasPromotionImage && !expired) || (locked && lockedLink && !expired)) {
    return <img className='arrow-button' src='assets/icons/pointer.svg' />;
  } else {
    return <img className='arrow-button' src='assets/icons/pointer-inactive.svg' />;
  }
};

const TitleDescriptionWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { width } = useWindowSize();

  if ((width ?? 0) < 768) {
    return <div className='title-description-wrapper'>{children}</div>;
  } else {
    return <>{children}</>;
  }
};

interface AllInOneCourseCardProps {
  userCourse?: UserCourse;
  title?: string;
  description?: string;
  hideDescription?: boolean;
  showCompactDescription?: boolean;
  bottomDescription?: string | React.ReactNode;
  progress?: number;
  link?: string;
  icon?: string | boolean;
  wideIcon?: boolean;
  inlineIcons?: boolean;
  locked?: boolean;
  lockedLink?: CourseCardLink;
  hasPromotionImage?: boolean;
  hasChevronIcon?: boolean;
  isCompact?: boolean;
  className?: string;
}

const AllInOneCourseCard: React.FC<AllInOneCourseCardProps> = ({
  userCourse,
  title,
  description,
  hideDescription,
  showCompactDescription,
  bottomDescription,
  progress,
  link,
  icon,
  wideIcon,
  inlineIcons,
  locked,
  lockedLink,
  hasPromotionImage,
  hasChevronIcon,
  isCompact,
  className,
}) => {
  const isLocked = () => {
    if (userCourse) {
      return !userCourse.active || !userCourse.claimed || userCourse.expired;
    } else {
      return locked;
    }
  };

  const getClasses = () => {
    const classes = ['card', 'all-in-one-course-card', 'scale-effect'];

    if (hasPromotionImage) {
      classes.push('showcase-card');
    }

    if (isCompact) {
      classes.push('compact-card');
    }

    if (showCompactDescription) {
      classes.push('show-compact-description');
    }

    if (userCourse?.expired) {
      classes.push('expired-card');
    }

    if (isLocked()) {
      classes.push('locked-card');

      if (lockedLink) {
        classes.push('has-locked-link');
      }
    }

    if (inlineIcons) {
      classes.push('inline-icons');
    }

    if (hasChevronIcon) {
      classes.push('has-chevron-icon');
    }

    if (className) {
      classes.push(className);
    }

    return classes.join(' ');
  };

  const getLink: () => CourseCardLink = () => {
    if (userCourse) {
      if (userCourse.claimed) {
        if (userCourse.active) {
          return {
            url:
              '/course/' +
              userCourse.identifier.toLowerCase() +
              '/learnmode' +
              (userCourse.current_chapter ? '/' + userCourse.current_chapter.id : ''),
            courseIdentifier: userCourse.identifier,
            type: 'internal',
          };
        } else {
          const upgradeLink = getCourseUpgradeShopLink([userCourse.identifier]);

          if (upgradeLink) {
            return { url: upgradeLink, courseIdentifier: userCourse.identifier, type: 'upgrade' };
          }
        }
      }

      if (hasPromotionImage) {
        return {
          url: getCourseSellupPageLink(userCourse.identifier),
          courseIdentifier: userCourse.identifier,
          type: 'upsell',
        };
      }
    }

    return { url: link ?? '' };
  };

  const getDescription = () => {
    if (userCourse && userCourse.claimed && userCourse.active && !userCourse.expired) {
      return userCourse.current_chapter
        ? `Lektion ${userCourse.current_chapter.lesson_number}: ${userCourse.current_chapter.title}`
        : 'Kurs abgeschlossen';
    }

    return description;
  };

  const getExpirationNote = () => {
    if (userCourse && userCourse.claimed) {
      const validUntil = getValidUntil(userCourse);

      if (validUntil.expirationText) {
        return (
          <div className='expiration-note'>
            <img src={`assets/icons/${validUntil.icon}`} />{' '}
            <p className='description'>{validUntil.expirationText}</p>
          </div>
        );
      }
    }

    return <></>;
  };

  return (
    <CourseCardWrapper
      link={getLink()}
      locked={isLocked()}
      lockedLink={lockedLink}
      expired={userCourse?.expired ?? false}
      hasPromotionImage={hasPromotionImage}
      className={getClasses()}
    >
      {!inlineIcons && <Icon icon={icon} locked={isLocked()} wideIcon={wideIcon} />}
      <TitleDescriptionWrapper>
        <>
          <div className='course-title'>
            {inlineIcons && <Icon icon={icon} locked={isLocked()} wideIcon={wideIcon} />}
            <h4>{title ?? userCourse?.title}</h4>
            {hasChevronIcon && inlineIcons && (
              <ChevronIcon
                link={getLink()}
                locked={isLocked()}
                lockedLink={lockedLink}
                expired={userCourse?.expired ?? false}
                hasPromotionImage={hasPromotionImage}
              />
            )}
            {getExpirationNote()}
          </div>
          <div className='course-description-and-progress'>
            {!hideDescription && getDescription() && (
              <p className='description text-muted'>{getDescription()}</p>
            )}
            {(progress !== undefined ||
              (userCourse && userCourse.active && userCourse.claimed && !userCourse.expired)) && (
              <ProgressBar
                className='progressbar-blue'
                progress={progress !== undefined ? progress : (userCourse?.progress ?? 0)}
                showProgressNumber={!isLocked()}
              />
            )}
            {bottomDescription && <p className='description mt-4 mb-0'>{bottomDescription}</p>}
          </div>
        </>
      </TitleDescriptionWrapper>
      {hasChevronIcon && !inlineIcons && (
        <ChevronIcon
          link={getLink()}
          locked={isLocked()}
          lockedLink={lockedLink}
          expired={userCourse?.expired ?? false}
          hasPromotionImage={hasPromotionImage}
        />
      )}
      {hasPromotionImage && userCourse && (!userCourse.active || !userCourse.claimed) && (
        <div
          className='course-promotion-image'
          style={{
            backgroundImage: `url('${getCourseImage(userCourse.identifier)}')`,
          }}
        ></div>
      )}
    </CourseCardWrapper>
  );
};

export default AllInOneCourseCard;
