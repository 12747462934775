import { TCountryCode, getEmojiFlag } from 'countries-list';
import { DateTime } from 'luxon';
import React, { FormEvent, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { useRegistrationDataState } from '../../../contexts/registration-data-context';
import { RegistrationData } from '../../../reducers/registration-data-reducer';
import { getCountryOptions } from '../../../utils/register-util';
import B1Button from '../../buttons/B1Button';
import Twemoji from '../../twemoji/Twemoji';
import B1Input from '../B1Input';
import B1Select from '../B1Select';

import './PersonalDataForm.scss';

const SEXES = {
  m: 'männlich',
  w: 'weiblich',
  d: 'divers',
};

interface PersonalDataFormProps {
  hideNameInfo?: boolean;
  submitButtonText?: string;
  onSubmit: () => void;
}

const PersonalDataForm: React.FC<PersonalDataFormProps> = ({
  hideNameInfo,
  submitButtonText,
  onSubmit,
}) => {
  const [registrationData, dispatchRegistrationData] = useRegistrationDataState();
  const [firstNameFeedback, setFirstNameFeedback] = useState(false);
  const [lastNameFeedback, setLastNameFeedback] = useState(false);
  const [nationalityFeedback, setNationalityFeedback] = useState(false);
  const [dateOfBirthFeedback, setDateOfBirthFeedback] = useState(false);
  const [birthPlaceFeedback, setBirthPlaceFeedback] = useState(false);
  const [sexFeedback, setSexFeedback] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setFirstNameFeedback(false);
    setLastNameFeedback(false);
    setNationalityFeedback(false);
    setDateOfBirthFeedback(false);
    setBirthPlaceFeedback(false);
    setSexFeedback(false);

    let incomplete = false;

    if (!hideNameInfo && !registrationData.data.firstname) {
      setFirstNameFeedback(true);
      incomplete = true;
    }
    if (!hideNameInfo && !registrationData.data.lastname) {
      setLastNameFeedback(true);
      incomplete = true;
    }
    if (!registrationData.data.nationality_code) {
      setNationalityFeedback(true);
      incomplete = true;
    }
    if (!registrationData.data.date_of_birth) {
      setDateOfBirthFeedback(true);
      incomplete = true;
    }
    if (!registrationData.data.birth_place) {
      setBirthPlaceFeedback(true);
      incomplete = true;
    }
    if (!registrationData.data.sex) {
      setSexFeedback(true);
      incomplete = true;
    }

    if (incomplete) return;

    onSubmit();
  };

  const getDateOfBirth = () => {
    return registrationData.data.date_of_birth
      ? DateTime.fromJSDate(registrationData.data.date_of_birth).toFormat('yyyy-MM-dd')
      : '';
  };

  const setValue = (
    key: keyof RegistrationData,
    event: React.FormEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    let value: string | Date = (event.target as HTMLInputElement).value;

    if (value) {
      switch (key) {
        case 'firstname':
          setFirstNameFeedback(false);
          break;
        case 'lastname':
          setLastNameFeedback(false);
          break;
        case 'nationality_code':
          setNationalityFeedback(false);
          break;
        case 'date_of_birth':
          value = new Date(value);
          setDateOfBirthFeedback(false);
          break;
        case 'birth_place':
          setBirthPlaceFeedback(false);
          break;
        case 'sex':
          setSexFeedback(false);
          break;
      }
    }

    dispatchRegistrationData({
      type: 'SET_DATA_KEY',
      key: key,
      value: value,
    });
  };

  return (
    <form className='b1-form personal-data-form' onSubmit={handleSubmit}>
      {!hideNameInfo && (
        <>
          <B1Input
            id='firstname'
            type='text'
            placeholder=''
            autoComplete='given-name'
            label='Vorname'
            value={registrationData.data.firstname}
            onInput={(e) => setValue('firstname', e)}
            feedback={firstNameFeedback ? 'Bitte gib Deinen Vornamen an.' : undefined}
            feedbackClass={firstNameFeedback ? 'warning' : undefined}
          />
          <B1Input
            id='lastname'
            type='text'
            placeholder=''
            autoComplete='family-name'
            label='Nachname'
            value={registrationData.data.lastname}
            onInput={(e) => setValue('lastname', e)}
            feedback={lastNameFeedback ? 'Bitte gib Deinen Nachnamen an.' : undefined}
            feedbackClass={lastNameFeedback ? 'warning' : undefined}
          />
        </>
      )}
      <div
        className={`b1-form-element-combined nationality${nationalityFeedback ? ' warning' : ''}`}
      >
        <label htmlFor='nationality'>Nationalität</label>
        <div className='combined-form'>
          {registrationData.data.nationality_code && (
            <Twemoji emoji={getEmojiFlag(registrationData.data.nationality_code as TCountryCode)} />
          )}
          <select
            id='nationality'
            autoComplete='country-name'
            value={registrationData.data.nationality_code}
            onInput={(e) => setValue('nationality_code', e)}
          >
            <option value=''>Wähle Deine Nationalität</option>
            {getCountryOptions().map((country, index) => (
              <option key={index} value={country.value}>
                {country.label}
              </option>
            ))}
          </select>
        </div>
        {nationalityFeedback && (
          <span className='form-feedback'>Bitte wähle Deine Nationalität aus.</span>
        )}
      </div>
      <Row>
        <Col sm='6'>
          <B1Input
            id='date_of_birth'
            type='date'
            placeholder=''
            autoComplete='bday'
            label='Geburtsdatum'
            min='1900-01-01'
            max={DateTime.now().minus({ years: 15 }).toFormat('yyyy-MM-dd')}
            value={getDateOfBirth()}
            onInput={(e) => setValue('date_of_birth', e)}
            feedback={dateOfBirthFeedback ? 'Bitte gib Dein Geburtsdatum an.' : undefined}
            feedbackClass={dateOfBirthFeedback ? 'warning' : undefined}
          />
        </Col>
        <Col sm='6'>
          <B1Input
            id='birth_place'
            type='text'
            placeholder=''
            autoComplete='address-level2'
            label='Geburtsort'
            value={registrationData.data.birth_place}
            onInput={(e) => setValue('birth_place', e)}
            feedback={birthPlaceFeedback ? 'Bitte gib Deinen Geburtsort an.' : undefined}
            feedbackClass={birthPlaceFeedback ? 'warning' : undefined}
          />
        </Col>
      </Row>
      <B1Select
        id='sex'
        placeholder='Wähle Dein Geschlecht aus'
        autoComplete='sex'
        label='Geschlecht'
        options={SEXES}
        value={registrationData.data.sex ?? ''}
        onInput={(e) => setValue('sex', e)}
        feedback={sexFeedback ? 'Bitte wähle Dein Geschlecht aus.' : undefined}
        feedbackClass={sexFeedback ? 'warning' : undefined}
      />

      <B1Button type='submit' className='button-block'>
        {submitButtonText ?? 'Weiter'}
      </B1Button>
    </form>
  );
};

export default PersonalDataForm;
