import { DateTime, DateTimeFormatOptions } from 'luxon';

export const SHORT_DATE_FORMAT: DateTimeFormatOptions = {
  ...DateTime.DATE_SHORT,
  day: '2-digit',
  month: '2-digit',
};

export const SHORT_DATE_TIME_FORMAT: DateTimeFormatOptions = {
  ...DateTime.DATETIME_SHORT,
  day: '2-digit',
  month: '2-digit',
};
