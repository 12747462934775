import * as Sentry from '@sentry/react';
import React, { PropsWithChildren, createContext, useEffect, useState } from 'react';

import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { useIonToast } from '@ionic/react';
import {
  getAccountStatus,
  login as loginAPI,
  logout as logoutAPI,
  register as registerAPI,
} from '../api/account/account';
import { resetPassword as resetPasswordAPI } from '../api/account/password';
import { getPushMessages } from '../api/account/push-messages';
import { AccountStatus } from '../api/interfaces/account-info';
import ApiError from '../api/interfaces/api-error';
import Code from '../api/interfaces/code';
import { PushMessage } from '../api/interfaces/push-message';
import { RegistrationData } from '../reducers/registration-data-reducer';
import { isApiError } from '../utils/api-util';
import { getStorage } from '../utils/storage';

type AuthContextType = {
  isAuthenticated: boolean | undefined;
  getUserData: () => Promise<AccountStatus | undefined>;
  login: (email: string, password: string, stayLoggedIn?: boolean) => Promise<boolean | ApiError>;
  logout: () => Promise<void>;
  resetPassword: (token: string, password: string) => Promise<true | ApiError>;
  register: (registrationData: RegistrationData, code: Code) => Promise<boolean>;
  refreshed: number;
  refresh: () => void;
  pushMessages: PushMessage[];
  loadPushMessages: () => Promise<void>;
  snowfallEnabled: boolean;
  toggleSnowfall: () => Promise<void>;
};

const initialAuthContext = {
  isAuthenticated: undefined,
  getUserData: () => {
    throw new Error('Method not implemented.');
  },
  login: () => {
    throw new Error('Method not implemented.');
  },
  logout: () => {
    throw new Error('Method not implemented.');
  },
  resetPassword: () => {
    throw new Error('Method not implemented.');
  },
  register: () => {
    throw new Error('Method not implemented.');
  },
  refreshed: 0,
  refresh: () => {
    throw new Error('Method not implemented.');
  },
  pushMessages: [],
  loadPushMessages: () => {
    throw new Error('Method not implemented.');
  },
  snowfallEnabled: false,
  toggleSnowfall: () => {
    throw new Error('Method not implemented.');
  },
};

export const AuthContext = createContext<AuthContextType>(initialAuthContext);

const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [presentToast] = useIonToast();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [refreshed, setRefreshed] = useState(0);
  const [lastAccountStatus, setLastAccountStatus] = useState<AccountStatus>();
  const [lastAccountStatusTimestamp, setLastAccountStatusTimestamp] = useState(0);
  const [pushMessages, setPushMessages] = useState<PushMessage[]>([]);
  const [snowfallEnabled, setSnowfallEnabled] = useState(false);

  const saveAuthResult = async (authResult: boolean | ApiError): Promise<void> => {
    if (authResult === true) {
      setIsAuthenticated(true);
      loadPushMessages();
    } else {
      setIsAuthenticated(false);
    }
  };

  const getUserData = async () => {
    const now = Date.now();
    let accountStatus = lastAccountStatus;

    if (now - lastAccountStatusTimestamp > 1000 * 5) {
      setLastAccountStatusTimestamp(now);

      const res = await getAccountStatus();

      if (isApiError(res)) {
        setIsAuthenticated(false);
        return;
      } else {
        accountStatus = res;
        setLastAccountStatus(res);
      }
    }

    if (!accountStatus) return;

    setIsAuthenticated(true);

    Sentry.setUser({ id: accountStatus.id, email: accountStatus.email });

    if (accountStatus.id !== undefined) {
      FirebaseAnalytics.setUserId({ userId: accountStatus.id.toString() });
    }

    return {
      ...accountStatus,
      birth_date: accountStatus.birth_date ? new Date(accountStatus.birth_date) : undefined,
    };
  };

  const login = async (
    email: string,
    password: string,
    stayLoggedIn?: boolean
  ): Promise<boolean | ApiError> => {
    const authResult = await loginAPI(email, password, stayLoggedIn);

    await saveAuthResult(authResult);

    await getStorage().set('push-messages-modal-shown', false);

    return authResult;
  };

  const logout = async (): Promise<void> => {
    await logoutAPI();
    await saveAuthResult(false);
  };

  const resetPassword = async (token: string, password: string): Promise<true | ApiError> => {
    const result = await resetPasswordAPI(token, password);

    await saveAuthResult(result === true);

    return result;
  };

  const register = async (registrationData: RegistrationData, code: Code) => {
    Object.keys(registrationData).forEach((key) => {
      const x = key as keyof RegistrationData;

      if (registrationData[x] === '') {
        delete registrationData[x];
      }
    });

    if (code.courses.every((course) => !course.driver_license_needed)) {
      delete registrationData.has_driver_license;
    }

    const registrationResult = await registerAPI(registrationData);

    if (registrationResult === true) {
      setIsAuthenticated(true);
      await getStorage().set('registered-at', Date.now());
      return true;
    } else {
      setIsAuthenticated(false);

      console.error(registrationResult);
      presentToast({
        message:
          'Bei der Registrierung ist ein Fehler aufgetreten.\n' +
          'Bitte versuche es später erneut oder melde Dich bei uns.',
        duration: 5000,
        position: 'bottom',
        color: 'danger',
      });

      return false;
    }
  };

  const refresh = () => {
    setRefreshed(refreshed + 1);
  };

  const loadPushMessages = async () => {
    const res = await getPushMessages();

    if (!isApiError(res)) {
      setPushMessages(res);
    }
  };

  useEffect(() => {
    loadPushMessages();

    getStorage()
      .get('snowfall')
      .then((value) => {
        if (value === null || value === undefined || typeof value !== 'boolean') {
          value = true;
        }

        setSnowfallEnabled(value);
      });
  }, []);

  const toggleSnowfall = async () => {
    setSnowfallEnabled(!snowfallEnabled);

    await getStorage().set('snowfall', !snowfallEnabled);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        getUserData,
        login,
        logout,
        resetPassword,
        register,
        refreshed,
        refresh,
        pushMessages,
        loadPushMessages,
        snowfallEnabled,
        toggleSnowfall,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
