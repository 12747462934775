import { isApiError } from '../../utils/api-util';
import { makeApiRequest } from '../api';
import { SmartmodeInterface, SmartmodeQuestion } from '../interfaces/smartmode';

export const getLessons = async (identifier: string) => {
  const res = await makeApiRequest<SmartmodeInterface>(
    'GET',
    `v2/courses/${identifier}/smart-mode`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const getLessonQuestions = async (identifier: string, lessonId: number) => {
  const res = await makeApiRequest<SmartmodeQuestion[]>(
    'GET',
    `v1/courses/${identifier}/smart-mode/${lessonId}`
  );

  if (isApiError(res)) {
    return res;
  }

  return res.data;
};

export const updateQuestionProgress = async (
  identifier: string,
  questionId: number,
  correct: boolean
) => {
  const res = await makeApiRequest('POST', `v1/courses/${identifier}/smart-mode`, undefined, {
    question_id: questionId,
    correct: correct,
  });

  if (isApiError(res)) {
    return res;
  }

  return true;
};

export const resetQuestionProgress = async (identifier: string, lessonId: number) => {
  const res = await makeApiRequest(
    'POST',
    `v1/courses/${identifier}/smart-mode/${lessonId}/reset-progress`
  );

  if (isApiError(res)) {
    return res;
  }

  return true;
};
