import { FirebaseMessaging } from '@capacitor-firebase/messaging';
import { App } from '@capacitor/app';
import { Capacitor, PermissionState } from '@capacitor/core';
import { useIonToast } from '@ionic/react';
import { AndroidSettings, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { informationCircleOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';

import { addPushToken } from '../../../api/account/account';
import { AuthContext } from '../../../providers/AuthProvider';
import { getMessagingToken } from '../../../utils/firebase-utils';
import { getStorage } from '../../../utils/storage';
import B1Button from '../../buttons/B1Button';
import B1Modal from '../B1Modal';

interface RequestPushPermissionModalProps {
  openAutomatically?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}

const RequestPushPermissionModal: React.FC<RequestPushPermissionModalProps> = ({
  openAutomatically,
  open = false,
  setOpen,
}) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [presentToast] = useIonToast();
  const [pushMessagesModalOpen, setPushMessagesModalOpen] = useState(false);
  const [permissionStatus, setPermissionStatus] = useState<PermissionState | 'unsupported'>();
  const [appIsActive, setAppIsActive] = useState(true);

  useEffect(() => {
    App.addListener('appStateChange', ({ isActive }) => {
      setAppIsActive(isActive);
    });
  }, []);

  useEffect(() => {
    FirebaseMessaging.isSupported().then(async (res) => {
      if (!res.isSupported) {
        setPermissionStatus('unsupported');
      } else {
        const permissionStatus = await FirebaseMessaging.checkPermissions();

        setPermissionStatus(permissionStatus.receive);
      }
    });
  }, [appIsActive]);

  useEffect(() => {
    setPushMessagesModalOpen(open);
  }, [open]);

  useEffect(() => {
    if (isAuthenticated && permissionStatus !== undefined && permissionStatus !== 'unsupported') {
      if (permissionStatus !== 'granted') {
        if (openAutomatically) {
          const storage = getStorage();

          Promise.all([
            storage.get('push-messages-modal-shown'),
            storage.get('registered-at'),
          ]).then(([modalShown, registeredAt]) => {
            if (
              modalShown ||
              (registeredAt &&
                !isNaN(Number(registeredAt)) &&
                Date.now() - registeredAt < 1000 * 60 * 5)
            ) {
              return;
            }

            setPushMessagesModalOpen(true);
            storage.set('push-messages-modal-shown', true);
          });
        }
      } else {
        getMessagingToken().then(addPushToken);
      }
    }
  }, [isAuthenticated, openAutomatically, permissionStatus]);

  const handleModalClose = () => {
    setPushMessagesModalOpen(false);

    if (setOpen) {
      setOpen(false);
    }
  };

  const handleClickLaterButton = () => {
    setPushMessagesModalOpen(false);

    if (openAutomatically) {
      presentToast({
        message: 'Du kannst die Push-Nachrichten jederzeit auf Deiner Account-Seite aktivieren.',
        duration: 5000,
        color: 'light',
        icon: informationCircleOutline,
      });
    }
  };

  const handleActivatePushMessages = async () => {
    setPushMessagesModalOpen(false);

    const result = await FirebaseMessaging.requestPermissions();

    setPermissionStatus(result.receive);

    if (result.receive === 'granted') {
      const token = await getMessagingToken();

      addPushToken(token);
    } else {
      let message =
        'Wenn Du es Dir anders überlegst, kannst Du in den Einstellungen Deines Browsers die Berechtigung erteilen.';

      if (Capacitor.isNativePlatform()) {
        message =
          'Wenn Du es Dir anders überlegst, kannst Du die Einstellung jederzeit auf Deiner Account-Seite ändern.';
      }

      presentToast({
        message: `Du hast den Zugriff auf Push-Nachrichten abgelehnt.\n${message}`,
        duration: 5000,
        color: 'light',
        icon: informationCircleOutline,
      });
    }
  };

  const openNativeSettings = async () => {
    if (Capacitor.isNativePlatform()) {
      NativeSettings.open({
        optionIOS: IOSSettings.App,
        optionAndroid: AndroidSettings.AppNotification,
      });
    }
  };

  return (
    <B1Modal open={pushMessagesModalOpen} onModalClose={() => handleModalClose()}>
      <h1 className='emoji'>🔔</h1>
      <h2 className='no-hyphens mb-3'>Push-Nachrichten aktivieren</h2>
      <p className='mb-4'>
        Aktiviere Push-Nachrichten, um wichtige Informationen und Erinnerungen zu Deinen Kursen zu
        erhalten. Gelegentlich informieren wir Dich auch über aktuelle Angebote.
      </p>
      {permissionStatus === 'denied' && Capacitor.isNativePlatform() ? (
        <>
          <p className='mb-3'>
            Du hast die Benachrichtigungen blockiert. Bitte erlaube sie in den Einstellungen Deines
            Gerätes.
          </p>
          <B1Button className='button-block' click={() => openNativeSettings()}>
            Einstellungen öffnen
          </B1Button>
        </>
      ) : (
        <div className='w-100 d-flex flex-nowrap gap-3'>
          <B1Button className='button-reverse button-block' click={() => handleClickLaterButton()}>
            Später
          </B1Button>
          <B1Button className='button-block' click={() => handleActivatePushMessages()}>
            Aktivieren
          </B1Button>
        </div>
      )}
    </B1Modal>
  );
};

export default RequestPushPermissionModal;
