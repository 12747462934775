import React, { useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { getVocabulary } from '../../../api/courses/radio';
import { Vocabulary } from '../../../api/interfaces/radio-course';
import B1Button from '../../../components/buttons/B1Button';
import { isApiError } from '../../../utils/api-util';

import './VocabularyTrainer.scss';

interface Word extends Vocabulary {
  showBackSide?: boolean;
}
type TVocabulary = {
  words: Word[];
  currentIndex: number;
};
type VocabularyAction =
  | { type: 'SET_WORDS'; words: Vocabulary[] }
  | { type: 'SET_CURRENT_INDEX'; index: number }
  | { type: 'FLIP_CARD'; index: number }
  | { type: 'SHOW_FRONT_SIDE'; index: number }
  | { type: 'SHUFFLE_WORDS' };

const vocabularyReducer = (state: TVocabulary, action: VocabularyAction): TVocabulary => {
  switch (action.type) {
    case 'SET_WORDS':
      return { ...state, words: action.words };
    case 'SET_CURRENT_INDEX':
      return { ...state, currentIndex: action.index };
    case 'FLIP_CARD':
      return {
        ...state,
        words: state.words.map((word, index) => {
          if (index === action.index) {
            return { ...word, showBackSide: !word.showBackSide };
          }
          return word;
        }),
      };
    case 'SHOW_FRONT_SIDE':
      return {
        ...state,
        words: state.words.map((word, index) => {
          if (index === action.index) {
            return { ...word, showBackSide: false };
          }
          return word;
        }),
      };
    case 'SHUFFLE_WORDS':
      return {
        ...state,
        words: [
          ...state.words.map((word) => {
            word.showBackSide = false;
            return word;
          }),
        ].sort(() => Math.random() - 0.5),
      };
    default:
      return state;
  }
};

const VocabularyTrainer: React.FC<{ identifier: string }> = ({ identifier }) => {
  const [vocabulary, dispatchVocabulary] = React.useReducer(vocabularyReducer, {
    words: [],
    currentIndex: 0,
  });
  const [showBackSide, setShowBackSide] = React.useState(false);

  useEffect(() => {
    getVocabulary(identifier).then((res) => {
      if (isApiError(res)) {
        console.error(res);
      } else {
        dispatchVocabulary({ type: 'SET_WORDS', words: res });
      }
    });
  }, [identifier]);

  const goToIndex = (index: number) => {
    dispatchVocabulary({ type: 'SET_CURRENT_INDEX', index });
  };

  return (
    <div className='vocabulary-trainer'>
      <h1>Vokabeltrainer</h1>

      <div className='language-buttons'>
        <B1Button
          className={showBackSide ? 'button-reverse' : ''}
          click={() => setShowBackSide(false)}
        >
          Deutsch
        </B1Button>
        <B1Button
          className={!showBackSide ? 'button-reverse' : ''}
          click={() => setShowBackSide(true)}
        >
          Englisch
        </B1Button>
      </div>

      <div className='vocabulary-card simple-card card-light-gray'>
        <Carousel
          interval={null}
          prevIcon={<img src='assets/icons/arrow-back.svg' />}
          nextIcon={<img src='assets/icons/arrow-forward.svg' />}
          controls={false}
          indicators={false}
          activeIndex={vocabulary.currentIndex}
          onSlid={(index, direction) => {
            if (direction === 'start') {
              dispatchVocabulary({ type: 'SHOW_FRONT_SIDE', index: index - 1 });
            } else {
              dispatchVocabulary({ type: 'SHOW_FRONT_SIDE', index: index + 1 });
            }
          }}
        >
          {vocabulary.words.map((word, index) => (
            <Carousel.Item key={index}>
              <div
                tabIndex={0}
                className={`vocabulary-card${word.showBackSide ? ' show-back' : ''}`}
                onClick={() =>
                  dispatchVocabulary({ type: 'FLIP_CARD', index: vocabulary.currentIndex })
                }
              >
                <span className='word word-front'>
                  {showBackSide ? word.word_englisch : word.word_german}
                </span>
                <span className='word word-back'>
                  {showBackSide ? word.word_german : word.word_englisch}
                </span>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className='vocabulary-controls'>
          <button
            type='button'
            className='back-button'
            onClick={() => goToIndex(vocabulary.currentIndex - 1)}
            disabled={vocabulary.currentIndex <= 0}
          >
            <MdArrowBack />
          </button>
          <span>
            {vocabulary.currentIndex + 1}/{vocabulary.words.length}
          </span>
          <button
            type='button'
            className='next-button'
            onClick={() => goToIndex(vocabulary.currentIndex + 1)}
            disabled={vocabulary.currentIndex + 1 >= vocabulary.words.length}
          >
            <MdArrowForward />
          </button>
        </div>
        <div className='card-controls'>
          <button
            type='button'
            className='scale-effect'
            onClick={() => dispatchVocabulary({ type: 'SHUFFLE_WORDS' })}
          >
            <img src='assets/icons/shuffle-circle.svg' />
          </button>
          <button
            type='button'
            className='scale-effect'
            onClick={() =>
              dispatchVocabulary({ type: 'FLIP_CARD', index: vocabulary.currentIndex })
            }
          >
            <img src='assets/icons/exchange-circle.svg' />
          </button>
        </div>
      </div>
    </div>
  );
};

export default VocabularyTrainer;
