import { IonRouterLink } from '@ionic/react';
import React from 'react';

import './NavigationCircles.scss';

interface NavigationCirclesProps {
  navigationCirclesRef?: React.RefObject<HTMLDivElement>;
  list: { id: number; nr?: number }[];
  currentId?: number;
  link?: string;
  onClick?: (index: number, id: number) => void;
}

const NavigationCircles: React.FC<NavigationCirclesProps> = ({
  navigationCirclesRef,
  list,
  currentId,
  link,
  onClick,
}) => {
  const getCircleClass = (id: number) => {
    const classes = ['navigation-circle', 'scale-effect'];

    if (id === currentId || ((currentId === undefined || isNaN(currentId)) && id === list[0].id)) {
      classes.push('navigation-circle-active');
    } else if (id !== currentId) {
      classes.push('navigation-circle-inactive');
    }

    return classes.join(' ');
  };
  return (
    <div className='navigation-circles' ref={navigationCirclesRef}>
      {list.map((element, index) =>
        link ? (
          <IonRouterLink
            key={index}
            className={getCircleClass(element.id)}
            routerLink={`${link}/${element.id}`}
            routerDirection={index > list.findIndex((e) => e.id === currentId) ? 'forward' : 'back'}
          >
            {element.nr ?? index + 1}
          </IonRouterLink>
        ) : (
          <button
            key={index}
            type='button'
            className={getCircleClass(element.id)}
            onClick={() => onClick && onClick(index, element.id)}
          >
            {element.nr ?? index + 1}
          </button>
        )
      )}
    </div>
  );
};

export default NavigationCircles;
