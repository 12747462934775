import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import React, { PropsWithChildren, StyleHTMLAttributes } from 'react';

import './CustomLink.scss';

interface CustomLinkProps
  extends PropsWithChildren,
    StyleHTMLAttributes<HTMLAnchorElement & HTMLButtonElement> {
  href?: string;
}

const CustomLink: React.FC<CustomLinkProps> = ({ href, onClick, children, ...props }) => {
  const handleClick = async (
    e: React.MouseEvent<HTMLAnchorElement & HTMLButtonElement, MouseEvent>
  ) => {
    if (e.isDefaultPrevented() || e.isPropagationStopped()) {
      return;
    }

    if (href && Capacitor.isNativePlatform()) {
      e.preventDefault();

      const deviceInfo = await Device.getInfo();

      Browser.open({
        url: href,
        windowName: '_blank',
        presentationStyle: deviceInfo.model.includes('iPad') ? 'fullscreen' : 'popover',
      });
    }

    if (onClick) {
      onClick(e);
    }
  };

  if (href) {
    return (
      <a href={href} onClick={handleClick} target='_blank' rel='noreferrer' {...props}>
        {children}
      </a>
    );
  } else {
    return (
      <button className='custom-link' onClick={handleClick} {...props} type='button'>
        {children}
      </button>
    );
  }
};

export default CustomLink;
