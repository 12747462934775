import React from 'react';

import { ClaimedSpinPrize, PrintPrizeColor } from '../../../api/interfaces/wheel-spins';

interface SizeChooserProps {
  selectedColor?: PrintPrizeColor;
  selectedSizeId?: number;
  onSizeChange: (size: number) => void;
}

const SizeChooser: React.FC<SizeChooserProps> = ({
  selectedColor,
  selectedSizeId,
  onSizeChange,
}) => {
  if (!selectedColor) {
    return <></>;
  }

  const getSelectedSize = () => {
    const selectedSize = selectedColor.sizes.find((s) => s.id === selectedSizeId);
    const availableSizes = selectedColor.sizes.filter((s) => s.available);

    if (selectedSize) {
      return selectedSize;
    } else if (availableSizes.length === 1) {
      return availableSizes[0];
    }
  };

  const selectedSize = getSelectedSize();

  return (
    <div className='size-chooser'>
      <span>Größe{selectedColor.sizes.length > 1 ? ' wählen' : ''}:&nbsp;</span>
      <div className='sizes'>
        {selectedColor.sizes.map((size) => (
          <label
            key={size.id}
            className='size-button'
            title={!size.available ? 'Nicht verfügbar' : size.name}
          >
            <input
              name='size'
              type='radio'
              checked={selectedSize?.id === size.id}
              disabled={!size.available}
              onChange={() => onSizeChange(size.id)}
            />
            {size.name}
          </label>
        ))}
      </div>
    </div>
  );
};

interface ColorSizeChooserProps {
  prize: ClaimedSpinPrize;
  selectedColorId?: number;
  selectedSizeId?: number;
  onColorChange: (color: number) => void;
  onSizeChange: (size: number | undefined) => void;
}

const ColorSizeChooser: React.FC<ColorSizeChooserProps> = ({
  prize,
  selectedColorId,
  selectedSizeId,
  onColorChange,
  onSizeChange,
}) => {
  if (prize.type !== 'print' || !prize.info?.colors) {
    return <></>;
  }

  const isAvailable = (color: { id: number; sizes: { available: boolean }[] }) => {
    return color.sizes.every((s) => s.available);
  };

  const getSelectedColor = () => {
    const selectedColor = prize.info?.colors?.find((c) => c.id === selectedColorId);
    const firstAvailableColor = prize.info?.colors?.find((c) => isAvailable(c));

    if (selectedColor && isAvailable(selectedColor)) {
      return selectedColor;
    } else if (firstAvailableColor) {
      return firstAvailableColor;
    }
  };

  const selectedColor = getSelectedColor();

  const handleColorChange = (color: PrintPrizeColor) => {
    onColorChange(color.id);

    if (!color.sizes.find((s) => s.id === selectedSizeId)?.available) {
      onSizeChange(undefined);
    }
  };

  return (
    <div className='size-color-chooser mb-4'>
      {prize.info.colors && (
        <div className='color-chooser'>
          <span>Farbe{prize.info.colors.length > 1 ? ' wählen' : ''}:&nbsp;</span>
          <div className='colors'>
            {prize.info.colors.map((color) => (
              <label
                key={color.id}
                className='color-button'
                title={!isAvailable(color) ? 'Nicht verfügbar' : color.name}
              >
                <input
                  name='color'
                  type='radio'
                  checked={selectedColor?.id === color.id}
                  disabled={!isAvailable(color)}
                  onChange={() => handleColorChange(color)}
                />
                <div className='color-indicator' style={{ backgroundColor: color.hex }}></div>
              </label>
            ))}
          </div>
        </div>
      )}
      <SizeChooser
        selectedColor={selectedColor}
        selectedSizeId={selectedSizeId}
        onSizeChange={onSizeChange}
      />
    </div>
  );
};

export default ColorSizeChooser;
